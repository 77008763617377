import React, { useState } from "react";
import Step1 from "../MultiStep/Step1";
import Step2 from "../MultiStep/Step2";
import Step3 from "../MultiStep/Step3";
import Step4 from "../MultiStep/Step4";
import Step5 from "../MultiStep/Step5";
import Step6 from "../MultiStep/Step6";

// import './integrateformpopup.css';

const IntegrateFormPopup = ({ onClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  function handleNextClick(event) {
    setCurrentStep(currentStep + 1);
  }
  const handlePrevClick = () => {
    setCurrentStep(currentStep - 1);
  };
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-popup">
          <i className="fa fa-times" onClick={onClose}></i>
        </button>
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="multisteps-form__form">
              {currentStep === 1 && (
                <Step1
                  nextClick={handleNextClick}
                  prevClick={handlePrevClick}
                  closeClick={onClose}
                />
              )}
              {currentStep === 2 && (
                <Step2
                  nextClick={handleNextClick}
                  prevClick={handlePrevClick}
                />
              )}
              {currentStep === 3 && (
                <Step3
                  nextClick={handleNextClick}
                  prevClick={handlePrevClick}
                />
              )}
              {currentStep === 4 && (
                <Step4
                  nextClick={handleNextClick}
                  prevClick={handlePrevClick}
                />
              )}
              {currentStep === 5 && (
                <Step5
                  nextClick={handleNextClick}
                  prevClick={handlePrevClick}
                />
              )}
              {currentStep === 6 && (
                <Step6
                  nextClick={handleNextClick}
                  prevClick={handlePrevClick}
                  closeClick={onClose}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrateFormPopup;
