import React, { useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  resetPassword,
  resetPasswordStatus,
} from "../../features/auth/authSlice";
import "./form.css";
import logo from "../../assets/images/Bellio-Digital-AI-Gradient.png";

const ResetForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, error } = useSelector((state) => state.auth);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  useEffect(() => {
    if (status === "succeeded") {
      toast.success("Password reset successful");
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } else if (error) {
      toast.error(error);
    }

    // Reset the status when the component is unmounted
    return () => {
      dispatch(resetPasswordStatus());
    };
  }, [status, error]);

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(12, "Password should be at least 12 characters")
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(resetPassword({ token, password: values.password }));
    },
  });

  return (
    <>
      <Toaster />
      <div className="container px-4 py-4 mx-auto auth-form">
        <div className="card card0 card-shadow">
          <div className="d-flex flex-lg-row flex-column-reverse">
            <div className="card card1 card-shadow">
              <div className="row justify-content-center my-auto">
                <div className="col-md-8 col-10 my-5">
                  <div className="row justify-content-center px-3 mb-5">
                    <img id="logo" src={logo} />
                  </div>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                      <label className="form-control-label text-muted">
                        New Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="New Password"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <div className="text-danger">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-control-label text-muted">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                      />
                      {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword ? (
                        <div className="text-danger">
                          {formik.errors.confirmPassword}
                        </div>
                      ) : null}
                    </div>
                    <div className="row justify-content-center my-2 px-3">
                      <button type="submit" className="btn-block btn-color">
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="card card2">
              <div className="my-auto mx-md-5 px-md-5 right">
                <h3 className="text-white">We are more than just a company</h3>
                <small className="text-white">
                  Your experience is something that we value above all else.
                  Your time is valuable. Your problems are our problems. Your
                  success is our success. If there is ever anything you need
                  help with, please don’t hesitate to reach out to us!
                </small>
                <div className="privacy-terms-links">
                  <a
                    href="https://belliodigital.ai/privacy-policy"
                    className="text-light"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  <span className="text-light"> | </span>
                  <a
                    href="https://belliodigital.ai/terms-of-service"
                    className="text-light"
                    target="_blank"
                  >
                    Terms of Service
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetForm;
