import React from 'react';
import { useSelector } from 'react-redux';
import celebrate from '../../assets/images/celebrate.png';
import sheetIcon from '../../assets/images/spreadsheet-icon.png';

const Step6 = ({ nextClick, prevClick, closeClick }) => {
    // Get the spreadsheetId from the Redux store
    const spreadsheetId = useSelector(state => state.form.spreadsheetId);

    // Construct the Google Spreadsheet URL
    const spreadsheetUrl = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit`;

    return (
        <div className="multisteps-form__panel pl-2 pr-2 rounded bg-white js-active" data-animation="scaleIn">
            <div className="multisteps-form__content">
                <div className="text-center mt-3">
                    <img src={celebrate} width="60" alt="Celebration" />
                    <h3 className='mb-3 mt-3'>Congratulations!</h3>
                    <p>You have successfully processed the data.</p>
                    <p>You can view the data in the Google Spreadsheet:</p>
                    <div className='sheet-icon'>
                        <a href={spreadsheetUrl} target="_blank" rel="noopener noreferrer">
                            <img
                                src={sheetIcon}
                                alt="Google Spreadsheet"
                                style={{
                                    width: '80px',
                                }}
                            />
                        </a>
                    </div>

                </div>
            </div>
            <div className="button-row d-flex mt-3 pb-3">
                <div className="ml-auto">
                    {/* <button className="btn btn-secondary mr-2 popup-button" onClick={prevClick}>
                        Previous
                    </button> */}
                    <button className="btn btn-primary popup-button" onClick={closeClick}>Finish</button>
                </div>
            </div>
        </div>
    )
}

export default Step6;