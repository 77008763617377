import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PSPopup from '../PSPopup/PSPopup';
import { fetchProducts, deleteProductService } from '../../features/productService/productServiceSlice';

function ProductsServicesList() {

    const dispatch = useDispatch();
    const { workspaceId } = useParams();
    const products = useSelector(state => state.productService.products);
    const fetchProductsStatus = useSelector(state => state.productService.fetchProductsStatus);
    const error = useSelector(state => state.productService.error);

    const [showPSPopup, setShowPSPopup] = useState(false);
    const [editingProductService, setEditingProductService] = useState(null);

    const services = products.filter(product => product.type === 'Service');
    const productItems = products.filter(product => product.type === 'Product');

    const handleEditClick = (productService) => {
        setEditingProductService(productService);
        setShowPSPopup(true);
    };

    const handleClosePopup = () => {
        setShowPSPopup(false);
        setEditingProductService(null);
    };

    const handleDeleteClick = (productServiceId) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this?");
        
        if (isConfirmed) {
            dispatch(deleteProductService(productServiceId))
            .then(() => {
                dispatch(fetchProducts(workspaceId));
            });
        }
    };    

    useEffect(() => {
        dispatch(fetchProducts(workspaceId));
    }, [dispatch, workspaceId]);

    if (fetchProductsStatus === 'loading') {
        return (
            <div className="text-center mt-4">
                <p>Loading...</p>
            </div>
        );
    }

    return (
        <>
            <div className="list-group pl-3 pr-3">
                <div>
                    <h3>Services</h3>
                </div>
                <div className="row">
                    {services.length > 0 ? (
                        services.map(service => (
                            <div className="col-md-4 mb-2" key={service._id}>
                                <div className="list-group-item rounded-lg d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <h6 className="mb-0">{service.name}</h6>
                                    </div>
                                    <div>
                                        <i className="fa fa-pencil mr-1" style={{ borderRadius: "15%", padding: "8px", backgroundColor: "#ff66ab", color: "white" }} aria-hidden="true" onClick={() => handleEditClick(service)}></i>
                                        <i className="fa fa-trash-o"
                                            style={{ borderRadius: "15%", padding: "8px", backgroundColor: "#FF9A5B", color: "white" }}
                                            aria-hidden="true"
                                            onClick={() => handleDeleteClick(service._id)} // For services
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="col-12 text-center">No services available.</p>
                    )}
                </div>
            </div>
            <div className="list-group pl-3 pr-3 mt-4">
                <div>
                    <h3>Products</h3>
                </div>
                <div className="row">
                    {productItems.length > 0 ? (
                        productItems.map(product => (
                            <div className="col-md-4" key={product._id}>
                                <div className="list-group-item rounded-lg d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <h6 className="mb-0">{product.name}</h6>
                                    </div>
                                    <div>
                                        <i className="fa fa-pencil mr-1" style={{ borderRadius: "15%", padding: "8px", backgroundColor: "#ff66ab", color: "white" }} aria-hidden="true" onClick={() => handleEditClick(product)}></i>
                                        <i className="fa fa-trash-o"
                                            style={{ borderRadius: "15%", padding: "8px", backgroundColor: "#FF9A5B", color: "white" }}
                                            aria-hidden="true"
                                            onClick={() => handleDeleteClick(product._id)}
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="col-12 text-center">No products available.</p>
                    )}
                </div>
            </div>
            {showPSPopup && <PSPopup onClose={handleClosePopup} productService={editingProductService} workspaceId={workspaceId} />}
        </>
    )
}

export default ProductsServicesList