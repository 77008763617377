import React, { useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activateAccount } from '../../features/auth/authSlice';
import "./AccountActivationMessage.css";
import logo from "../../assets/images/Bellio-Digital-AI-Gradient.png";

const AccountActivation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { AccountActivationStatus, AccountActivationError } = useSelector((state) => state.auth);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  useEffect(() => {
    // Reset the status when the component is unmounted
    return () => {
      dispatch(activateAccount(token));
    };
  }, []);

  return (
    <>
      <div className="container px-4 py-4 mx-auto auth-form">
        <div className="row justify-content-center">
          <div className="col-md-8 card1 card-shadow py-4">
            <div className="row justify-content-center px-3 mb-5">
              <img id="logo" src={logo} />
            </div>
            <h2 className={`text-center ${AccountActivationStatus === "succeeded" ? 'text-success' : 'text-danger'}`}>
              {AccountActivationStatus === "loading" ? "Activating your account..." : "Account Activation"}
            </h2>
            <p className={`text-center ${AccountActivationStatus === "succeeded" ? 'text-success' : 'text-danger'}`}>
              {AccountActivationStatus === "succeeded" ? "Your account has been successfully activated!" : "Invalid or expired activation token"}
            </p>
            {AccountActivationStatus === "succeeded" && (
              <div className="row justify-content-center my-auto">
                <div className="col-md-12 justify-content-center my-2 px-3">
                  <Link to="/login" className="btn-block btn-color" style={{textAlign: "center"}}>
                    Go to Login
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

    </>
  );
};

export default AccountActivation;