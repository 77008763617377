import React, { useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const APPopup = ({ onClose }) => {

    return (
        <>
            <div><Toaster /></div>
            <div className="popup-overlay">
                <div className="popup-content">
                    <div className="container">
                        <h3>Add Payment Method</h3>
                        <form enctype="multipart/form-data">
                            <div className="form-group">
                                <label className="form-label" for="businessName">Name on card</label>
                                <input
                                    type="text"
                                    id="businessName"
                                    className="form-control"
                                    placeholder="Your Name"
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label" for="businessName">Card number</label>
                                <input
                                    type="text"
                                    id="businessName"
                                    className="form-control"
                                    placeholder="0000 0000 0000 0000"
                                />
                            </div>
                            <div className='row'>
                            <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label" for="businessName">Expiry date</label>
                                <input
                                    type="text"
                                    id="businessName"
                                    className="form-control"
                                    placeholder="MM / YY"
                                />
                            </div>
                            </div>
                            <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label" for="businessName">CVC code</label>
                                <input
                                    type="text"
                                    id="businessName"
                                    className="form-control"
                                    placeholder="CVC"
                                />
                            </div>
                            </div>
                            </div>
                            <div className="popup-buttons">
                                <button className="btn btn-secondary mr-2 popup-button" onClick={onClose}>
                                    Cancel
                                </button>
                                <button className="btn btn-primary popup-button" type="submit">
                                    Add Card
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default APPopup;