import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { addWorkspace, updateWorkspace, resetWorkspaceStatus } from '../../features/workspace/workspaceSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './wspopup.css';

const WSPopup = ({ onClose, editMode, workspace }) => {
  const dispatch = useDispatch();
  const workspaces = useSelector((state) => state.workspace.workspaces);
  const planDetails = useSelector((state) => state.userAccount.planDetails);
  const addWorkspaceStatus = useSelector((state) => state.workspace.addWorkspaceStatus);
  const workspaceError = useSelector((state) => state.workspace.error);
  const updateWorkspaceStatus = useSelector((state) => state.workspace.updateWorkspaceStatus);
  const [previewImage, setPreviewImage] = useState(null);

  const isWorkspaceLimitReached = () => {
    // If the workspace limit is undefined or zero, assume no limit is set.
    const limit = planDetails?.workspaceLimit;
    if (limit === undefined || limit === 0) {
      return false;
    }
    return workspaces.length >= limit;
  };

  const validationSchema = Yup.object().shape({
    businessName: Yup.string()
      .required('Business Name is required'),
    businessDescription: Yup.string()
      .required('Business Description is required'),
    businessLogo: Yup.mixed()
      .nullable() // Allow null values
      .test('fileSize', 'File too large', value => !value || (value && value.size <= 1048576)) // 1MB
      .test('fileType', 'Unsupported File Format', value => !value || ['image/jpg', 'image/jpeg', 'image/png'].includes(value.type))
  });

  const formik = useFormik({
    initialValues: {
      businessName: editMode && workspace ? workspace.businessName : '',
      businessDescription: editMode && workspace ? workspace.businessDescription : '',
      businessLogo: null,
      existingLogo: editMode && workspace ? workspace.businessLogo : null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let formData = new FormData();
      formData.append('businessName', values.businessName);
      formData.append('businessDescription', values.businessDescription);

      // Only append the businessLogo if the user has selected a new file
      if (values.businessLogo) {
        formData.append('businessLogo', values.businessLogo);
      }

      if (editMode) {
        formData.append('id', workspace._id);
        // Dispatch an action to update the workspace

        dispatch(updateWorkspace(formData));
      } else {
        dispatch(addWorkspace(formData));
      }
    },
  });

  useEffect(() => {
    if (addWorkspaceStatus === 'succeeded') {
      toast.success('Workspace added successfully!', { duration: 2000 });
      setTimeout(() => {
        onClose();
        dispatch(resetWorkspaceStatus());
      }, 2000);
    } else if (updateWorkspaceStatus === 'succeeded') {
      toast.success('Workspace updated successfully!', { duration: 2000 });
      setTimeout(() => {
        onClose();
        dispatch(resetWorkspaceStatus());
      }, 2000);
    } else if (addWorkspaceStatus === 'failed' || updateWorkspaceStatus === 'failed') {
      toast.error(workspaceError || 'Failed to process workspace.');
      setTimeout(() => {
        dispatch(resetWorkspaceStatus());
      }, 2000);
    }
  }, [addWorkspaceStatus, updateWorkspaceStatus, workspaceError, dispatch, onClose]);

  if (isWorkspaceLimitReached()) {
    return (
      <div className="popup-overlay">
        <div className="popup-content">
          <div className="container">
            <h3>Workspace Limit Reached</h3>
            <p>You have reached your limit of {planDetails?.workspaceLimit} workspaces.</p>
            <div className="popup-buttons">
              <button className="btn btn-secondary mr-2 popup-button" onClick={onClose}>
                Cancel
              </button>
              <Link to={`/upgrade`} className="btn btn-primary popup-button">Upgrade Plan</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div><Toaster /></div>
      <div className="popup-overlay">
        <div className="popup-content">
          <div className="container">
            <h3>{editMode ? 'Edit Workspace' : 'Add Workspace'}</h3>
            <form onSubmit={formik.handleSubmit} enctype="multipart/form-data">
              <div className="form-group">
                <label className="form-label" for="businessName">Business Name</label>
                <input
                  type="text"
                  id="businessName"
                  className="form-control"
                  placeholder="Enter Business Name"
                  onChange={formik.handleChange}
                  value={formik.values.businessName}
                />
                {formik.errors.businessName && formik.touched.businessName && (
                  <div className="error">{formik.errors.businessName}</div>
                )}
              </div>
              <div className="form-group">
                <label className="form-label" for="businessDescription">Business Description</label>
                <textarea
                  id="businessDescription"
                  className="form-control"
                  rows="4"
                  placeholder="Enter Business Description"
                  onChange={formik.handleChange}
                  value={formik.values.businessDescription}
                />
                {formik.errors.businessDescription && formik.touched.businessDescription && (
                  <div className="error">{formik.errors.businessDescription}</div>
                )}
              </div>
              <div className="form-group">
                <label className="form-label" for="businessLogo">Business Logo</label>
                {previewImage ? (
                  <div className="selected-logo-card">
                    <img
                      src={previewImage}
                      alt="Selected Business Logo"
                      className="workspace-logo-image"
                    />
                  </div>
                ) : formik.values.existingLogo ? (
                  <div className="workspace-logo-card">
                    <img
                      src={`${formik.values.existingLogo}`}
                      alt="Existing Business Logo"
                      className="workspace-logo-image"
                    />
                    {/* <div>{formik.values.existingLogo}</div> */}
                  </div>
                ) : null}
                <input
                  type="file"
                  id="businessLogo"
                  className="form-control"
                  accept="image/*"
                  onChange={(event) => {
                    formik.setFieldValue('businessLogo', event.currentTarget.files[0]);
                    const file = event.currentTarget.files[0];
                    if (file) {
                      const imageUrl = URL.createObjectURL(file);
                      setPreviewImage(imageUrl);
                    } else {
                      setPreviewImage(null);
                    }
                  }}
                />
                {formik.errors.businessLogo && formik.touched.businessLogo && (
                  <div className="error">{formik.errors.businessLogo}</div>
                )}
              </div>
              <div className="popup-buttons">
                <button className="btn btn-secondary mr-2 popup-button" onClick={onClose}>
                  Cancel
                </button>
                <button className="btn btn-primary popup-button" type="submit">
                  {editMode ? 'Update Workspace' : 'Add Workspace'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default WSPopup;