import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  signup,
  activateAccountAPI,
  signin,
  logoutAPI,
  updateProfile,
  changePassword,
  fetchUserAPI,
  forgotPasswordAPI,
  resetPasswordAPI,
  fetchCsrfToken,
  fetchAllowedDomains,
} from "./AuthAPI";
import { setUserPlanDetails } from "../userAccount/userAccountSlice";
import { fetchUserAccountDataAPI } from "../userAccount/userAccountAPI";
import { fetchPlanDetailsAPI } from "../plan/PlanAPI";

// Async thunk for signup
export const signupUser = createAsyncThunk(
  "auth/signupUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await signup(userData);
      return response.data;
    } catch (error) {
      // Ensure we are capturing the backend message correctly
      const message = error.response?.data?.message || "An unexpected error occurred";
      return rejectWithValue(message);
    }
  }
);

// Async thunk for account activation
export const activateAccount = createAsyncThunk(
  "auth/activateAccount",
  async (token, { rejectWithValue }) => {
    try {
      const response = await activateAccountAPI(token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Async thunk for login
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (userData, { dispatch, rejectWithValue }) => {
    try {
      const response = await signin(userData);
      const user = response.data;
      // Fetch user account data
      const userAccountResponse = await fetchUserAccountDataAPI(user.user._id);
      const userAccount = userAccountResponse.data;

      // Fetch plan details if the plan ID is available
      if (userAccount.subscription && userAccount.subscription.plan) {
        const planResponse = await fetchPlanDetailsAPI(
          userAccount.subscription.plan
        );
        const planDetails = planResponse.data;

        // Dispatch an action to set the user's plan details
        dispatch(setUserPlanDetails(planDetails));
      }

      return user;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("An error occurred while logging in.");
    }
  }
);

// productServiceSlice.js
export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await logoutAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUser = createAsyncThunk(
  "auth/fetchUser",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const userResponse = await fetchUserAPI();
      const user = userResponse.data;
      // Fetch user account data using the existing API function
      const userAccountResponse = await fetchUserAccountDataAPI(user._id);
      const userAccount = userAccountResponse.data;

      // Fetch plan details if the plan ID is available
      if (userAccount.subscription && userAccount.subscription.plan) {
        const planResponse = await fetchPlanDetailsAPI(
          userAccount.subscription.plan
        );
        const planDetails = planResponse.data;

        // Dispatch an action to set the user's plan details
        dispatch(setUserPlanDetails(planDetails));
      }

      return { message: "Register Successfull,", user: user };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for forgot password
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (email, { rejectWithValue }) => {
    try {
      const response = await forgotPasswordAPI(email);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for reset password
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ token, password }, { rejectWithValue }) => {
    try {
      const response = await resetPasswordAPI({ token, password });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for update user profile
export const updateUserProfile = createAsyncThunk(
  "auth/updateUserProfile",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await updateProfile(userData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("An error occurred while updating the profile.");
    }
  }
);

// Async thunk for update password
export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async (passwordData, { rejectWithValue }) => {
    try {
      const response = await changePassword(passwordData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("An error occurred while changing the password.");
    }
  }
);
// Async thunk for fetch CSRF token
export const fetchToken = createAsyncThunk(
  "auth/fetchToken",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchCsrfToken();
      return response.data;
    } catch (error) {
      return rejectWithValue("An error occurred while getting token.");
    }
  }
);
// Async thunk for fetching allowed domains
export const fetchAllowedDomainsAsync = createAsyncThunk(
  "auth/fetchAllowedDomains",
  async () => {
    try {
      const response = await fetchAllowedDomains();
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch allowed domains");
    }
  }
);

const initialState = {
  user: null,
  status: "idle",
  isAuthenticated: false,
  error: null,
  signupMethod: null,
  forgotPasswordLoading: false,
  forgotPasswordSuccess: false,
  forgotPasswordError: null,
  loading: false,
  allowedDomains: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.signupMethod = null;
    },
    resetForgotPasswordState: (state) => {
      state.forgotPasswordLoading = false;
      state.forgotPasswordSuccess = false;
      state.forgotPasswordError = null;
    },
    resetPasswordStatus: (state) => {
      state.status = "idle";
    },
    resetAuth: (state) => {
      state.user = null;
      state.status = "idle";
      state.isAuthenticated = false;
      state.error = null;
      state.signupMethod = null;
      state.forgotPasswordLoading = false;
      state.forgotPasswordSuccess = false;
      state.forgotPasswordError = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    checkAllowedDomains: (state, action) => {
      const { redirectUrl } = action.payload;
      // Check if redirecting URL exists in the allowed domains array
      const isAllowed = state.allowedDomains.includes(redirectUrl);
      if (isAllowed) {
        // Allow the redirection
        window.location.href = redirectUrl;
      } else {
        // Handle not allowed scenario
        console.log("Redirect URL is not allowed.");
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Signup cases
      .addCase(signupUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "An unknown error occurred during signup.";
      })
      // Login cases
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
        state.error = null;
        state.isAuthenticated = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error =
            typeof action.payload === "string"
              ? action.payload
              : action.payload.message;
        } else {
          state.error = action.error.message;
        }
      })
      .addCase(logout.fulfilled, (state) => {
        // Reset the state to its initial value on successful logout
        return initialState;
      })
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
        state.signupMethod = "google";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.status = "succeeded";
        state.user = action.payload;
        state.error = null;
        state.signupMethod = "google";
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.signupMethod = "google";
      })
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPasswordLoading = true;
        state.forgotPasswordSuccess = false;
        state.forgotPasswordError = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPasswordLoading = false;
        state.forgotPasswordSuccess = true;
        state.forgotPasswordError = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.forgotPasswordLoading = false;
        state.forgotPasswordSuccess = false;
        state.forgotPasswordError = action.error.message;
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
        state.error = null;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updatePassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // CSRF token cases
      .addCase(fetchToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchToken.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchToken.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchAllowedDomainsAsync.fulfilled, (state, action) => {
        console.log("Fetched Allowed Domains:");

        // Update the allowedDomains array in the state
        state.allowedDomains = action.payload.allowedDestinations;
      })
      .addCase(fetchAllowedDomainsAsync.rejected, (state, action) => {
        console.log("Failed to fetch allowed domains:");
      })
      // Account Activation cases
      .addCase(activateAccount.pending, (state) => {
        state.AccountActivationStatus = "loading";
      })
      .addCase(activateAccount.fulfilled, (state) => {
        state.AccountActivationStatus = "succeeded";
        state.AccountActivationError = null;
      })
      .addCase(activateAccount.rejected, (state, action) => {
        state.AccountActivationStatus = "failed";
        state.AccountActivationError = action.error.message;
      });
  },
});

export const {
  resetAuthStatus,
  resetForgotPasswordState,
  resetPasswordStatus,
  resetAuth,
  setLoading,
  checkAllowedDomains,
} = authSlice.actions;
export default authSlice.reducer;
