import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast, Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import { updateUserProfile, updatePassword } from '../../features/auth/authSlice';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import panda from '../../assets/images/default-images/panda.png';

const Profile = () => {
    const fileInputRef = useRef(null);
    const user = useSelector(state => state.auth.user.user);
    // Check if user.profileImage exists, if not, use the default panda image
    const initialImageSrc = user.profileImage ? user.profileImage : panda;
    const [imageSrc, setImageSrc] = useState(initialImageSrc); // Initially set to panda image
    const [selectedFile, setSelectedFile] = useState(null);
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email, // This will be read-only
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
        }),
        onSubmit: values => {
            const formData = new FormData();
            formData.append('firstName', values.firstName);
            formData.append('lastName', values.lastName);
            if (selectedFile) {
                formData.append('profileImage', selectedFile);
            }

            dispatch(updateUserProfile(formData))
                .then(action => {
                    if (action.error) {
                        console.error("Error updating profile:", action.error);
                        toast.error('Error updating profile. Please try again.', { duration: 2000 });
                    } else {
                        toast.success('Profile updated successfully!', { duration: 2000 });
                    }
                });
        },
    });

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file); // Set the selected file
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const passwordFormik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            currentPassword: Yup.string().required('Required'),
            newPassword: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
                .required('Required'),
        }),
        onSubmit: values => {
            dispatch(updatePassword(values))
                .then(action => {
                    if (action.error) {
                        console.error("Error updating password:", action.error);
                        toast.error('Error updating password. Please try again.', { duration: 2000 });
                    } else {
                        toast.success('Password updated successfully!', { duration: 2000 });
                    }
                });
        },
    });

    return (
        <>
            <Toaster />
            <div className="container mt-4">
                <div className="settings-header mb-4">
                    <h2>Settings</h2>
                </div>

                {/* Profile Details */}
                <div className="row">
                    <div className="col-md-6 mob-spacing">
                        <div className="section mb-4 campaign-card">
                            <h3>Profile Details</h3>
                            <div className="d-flex align-items-center mb-3">
                                <div className="profile-image mr-3">
                                    <img src={imageSrc} alt="Profile" className="rounded-circle" style={{ width: '100px', height: '100px' }} />
                                </div>
                                <button className="btn card-button" onClick={triggerFileInput}>Change Picture</button>
                                <input type="file" ref={fileInputRef} onChange={handleImageChange} style={{ display: 'none' }} />
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        name="firstName"
                                        onChange={formik.handleChange}
                                        value={formik.values.firstName}
                                    />
                                    {formik.errors.firstName && formik.touched.firstName && (
                                        <div className="text-danger">{formik.errors.firstName}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                        name="lastName"
                                        onChange={formik.handleChange}
                                        value={formik.values.lastName}
                                    />
                                    {formik.errors.lastName && formik.touched.lastName && (
                                        <div className="text-danger">{formik.errors.lastName}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        readOnly
                                        value={formik.values.email}
                                    />
                                </div>
                                <button className="btn btn-primary card-button w-100" type="submit">Update Profile</button>
                            </form>
                            {/* <button className="btn btn-primary card-button">Update Profile</button> */}
                        </div>
                    </div>
                    {/* Change Password */}
                    <div className="col-md-6">
                        <div className="section mb-4 campaign-card">
                            <h3>Change Password</h3>
                            <div>
                                <form onSubmit={passwordFormik.handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Current Password"
                                            name="currentPassword"
                                            onChange={passwordFormik.handleChange}
                                            value={passwordFormik.values.currentPassword}
                                        />
                                        {passwordFormik.errors.currentPassword && passwordFormik.touched.currentPassword && (
                                            <div className="text-danger">{passwordFormik.errors.currentPassword}</div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="New Password"
                                            name="newPassword"
                                            onChange={passwordFormik.handleChange}
                                            value={passwordFormik.values.newPassword}
                                        />
                                        {passwordFormik.errors.newPassword && passwordFormik.touched.newPassword && (
                                            <div className="text-danger">{passwordFormik.errors.newPassword}</div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Confirm New Password"
                                            name="confirmPassword"
                                            onChange={passwordFormik.handleChange}
                                            value={passwordFormik.values.confirmPassword}
                                        />
                                        {passwordFormik.errors.confirmPassword && passwordFormik.touched.confirmPassword && (
                                            <div className="text-danger">{passwordFormik.errors.confirmPassword}</div>
                                        )}
                                    </div>
                                    <button className="btn btn-primary card-button w-100" type="submit">Update Password</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile;