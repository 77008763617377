import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { store, persistor } from './app/store';  
import App from './App';

const stripePromise = loadStripe('pk_test_51OPO8lGW1jI9xUgOtWjKbfNUAan2vfkkx2xO2xffmfBm5D7HVfe4shzY2DCNnDdSe3MTQ6C50kIC1JFWOpLrUZ6100XugpV7K3');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <Elements stripe={stripePromise}>
        <App />
        </Elements>
      </PersistGate>
    </Provider>
);