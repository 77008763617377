import React, { useState, useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { addProductService, updateProductService, resetProductServiceStatus } from '../../features/productService/productServiceSlice';
import { generateExampleEmail, resetGeneratingStatusAndError } from '../../features/ai/aiSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './pspopup.css';
import magicIcon from '../../assets/images/default-images/magic-wand.png';

const PSPopup = ({ onClose, workspaceId, productService }) => {
    const dispatch = useDispatch();
    const productServiceStatus = useSelector((state) => state.productService.productServiceStatus);
    const productServiceError = useSelector((state) => state.productService.error);
    const generatingStatus = useSelector((state) => state.ai.generatingStatus);
    const generatingError = useSelector((state) => state.ai.error);
    const exampleEmail = useSelector((state) => state.ai.exampleEmail);

    const validationSchema = Yup.object().shape({
        type: Yup.string()
            .required('Type is required'),
        name: Yup.string()
            .required('Name is required'),
        contextAndLinks: Yup.string()
            .required('Context and Relevant Links are required'),
        importantRequirements: Yup.string()
            .required('Important Requirements are required'),
        exampleEmailConversation: Yup.string()
            .required('Example Email Conversation Requirements are required'),
    });

    const isEditing = !!productService;

    const formik = useFormik({
        initialValues: {
            type: productService?.type || '',
            name: productService?.name || '',
            contextAndLinks: productService?.contextAndLinks || '',
            importantRequirements: productService?.importantRequirements || '',
            exampleEmailConversation: productService?.exampleEmailConversation || ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const productServiceData = {
                ...values,
                workspaceId: workspaceId
            };

            if (isEditing) {
                // Dispatch an action to update the Product/Service
                dispatch(updateProductService({
                    productServiceId: productService._id,
                    updates: productServiceData
                }));
            } else {
                dispatch(addProductService(productServiceData));
            }
        },
    });

    const handleGenerateEmail = () => {
        const emailData = {
            type: formik.values.type,
            name: formik.values.name,
            contextAndRelevantLinks: formik.values.contextAndLinks,
            importantRequirements: formik.values.importantRequirements
        };

        dispatch(generateExampleEmail({ workspaceId, emailData }));
    };

    useEffect(() => {
        if (productServiceStatus === 'succeeded') {
            const message = isEditing ? 'Product/Service updated successfully!' : 'Product/Service added successfully!';
            toast.success(message, { duration: 2000 });
            setTimeout(() => {
                onClose();
                dispatch(resetProductServiceStatus());
            }, 2000);
        } else if (productServiceStatus === 'failed') {
            toast.error(productServiceError || 'Failed to process request.');
            setTimeout(() => {
                dispatch(resetProductServiceStatus());
            }, 2000);
        }
    }, [productServiceStatus, productServiceError, dispatch]);

    useEffect(() => {
        if (generatingStatus === 'succeeded') {
            formik.setFieldValue('exampleEmailConversation', exampleEmail);
        } else if (generatingStatus === 'failed') {
            toast.error(generatingError);
        }

        // Reset generating status only after loading is complete
        if (generatingStatus === 'succeeded' || generatingStatus === 'failed') {
            dispatch(resetGeneratingStatusAndError());
        }
    }, [generatingStatus, generatingError, exampleEmail, formik, dispatch]);

    return (
        <>
            <div><Toaster /></div>
            <div className="popup-overlay">
                <div className="popup-content" style={{ height: 'calc(90% - 40px)', overflowY: 'auto' }}>
                    <div className="container">
                        <h3>Add Product/Service</h3>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="type">Select Type</label>
                                <select
                                    id="type"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.type}
                                    required
                                >
                                    <option value="">Select Type</option>
                                    <option value="Service">Service</option>
                                    <option value="Product">Product</option>
                                </select>
                                {formik.touched.type && formik.errors.type ? (
                                    <div className="error">{formik.errors.type}</div>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    className="form-control"
                                    placeholder="Enter Name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                    required
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <div className="error">{formik.errors.name}</div>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="contextAndLinks">Context and Relevant Links</label>
                                <textarea
                                    id="contextAndLinks"
                                    className="form-control"
                                    rows="4"
                                    placeholder="Enter Context and Relevant Links"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.contextAndLinks}
                                    required
                                />
                                {formik.touched.contextAndLinks && formik.errors.contextAndLinks ? (
                                    <div className="error">{formik.errors.contextAndLinks}</div>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="importantRequirements">Important Requirements</label>
                                <textarea
                                    id="importantRequirements"
                                    className="form-control"
                                    rows="4"
                                    placeholder="Enter Important Requirements"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.importantRequirements}
                                    required
                                />
                                {formik.touched.importantRequirements && formik.errors.importantRequirements ? (
                                    <div className="error">{formik.errors.importantRequirements}</div>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <div className="label-with-icon">
                                    <label className="form-label" htmlFor="exampleEmailConversation">
                                        Example Email Conversation
                                    </label>
                                    <img
                                        src={magicIcon}
                                        width={23}
                                        alt="Generate"
                                        className="magic-icon"
                                        onClick={handleGenerateEmail}
                                    />
                                </div>
                                <textarea
                                    id="exampleEmailConversation"
                                    className="form-control"
                                    rows="6"
                                    placeholder={generatingStatus === 'loading' ? "Generating email..." : "Enter Example Email Conversation"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.exampleEmailConversation}
                                    required
                                    disabled={generatingStatus === 'loading'}
                                />
                                {formik.touched.exampleEmailConversation && formik.errors.exampleEmailConversation ? (
                                    <div className="error">{formik.errors.exampleEmailConversation}</div>
                                ) : null}
                            </div>
                            <div className="popup-buttons">
                                <button className="btn btn-secondary mr-2 popup-button" onClick={onClose}>
                                    Cancel
                                </button>
                                <button className="btn btn-primary popup-button" type="submit">
                                    {isEditing ? 'Update' : 'Add'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PSPopup;