import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFormsForWorkspaceAsync, deleteFormAsync, resetPartialForm, setFormData } from '../../features/form/formSlice';
import connected from '../../assets/images/connected.png';

const FormsList = ({ onEditForm }) => {
    const { workspaceId } = useParams();
    const dispatch = useDispatch();

    const forms = useSelector(state => state.form.forms);
    const status = useSelector(state => state.form.status);

    useEffect(() => {
        dispatch(fetchFormsForWorkspaceAsync(workspaceId));
    }, [dispatch, workspaceId]);

    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    const handleEdit = (form, webhookUniqueId) => {
        dispatch(resetPartialForm());
        dispatch(setFormData({ form: form, webhookUniqueId: webhookUniqueId }));
        onEditForm();
    };

    const handleDelete = (formId, webhookId) => {
        if (window.confirm('Are you sure you want to delete this form?')) {
            dispatch(deleteFormAsync({ formId, webhookId }));
        }
    };

    return (
        <div className="list-group pl-3 pr-3">
            {forms.length === 0 ? (
                <div className="text-center mt-4">
                    <h4>No forms available.</h4>
                    <p>Click on the "+" icon to create a new form.</p>
                </div>
            ) : (
                forms.map(form => (
                    <div key={form.form._id} className="list-group-item py-3 rounded-lg">
                        <div className="d-flex w-100 justify-content-between">
                            <div className="d-flex align-items-center">
                                <img src={connected} alt="Integration" width="70" className="mr-2" />
                                <h4 className="mb-1">{form.form.name}</h4>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <i className="fa fa-pencil mr-1"
                                style={{ borderRadius: "15%", padding: "8px", backgroundColor: "#ff66ab", color: "white" }}
                                aria-hidden="true"
                                onClick={() => handleEdit(form.form, form.webhookUniqueId)}
                            ></i>
                            <i className="fa fa-trash-o"
                                style={{ borderRadius: "15%", padding: "8px", backgroundColor: "#FF9A5B", color: "white" }}
                                aria-hidden="true"
                                onClick={() => handleDelete(form.form._id, form.form.webhookId._id)}
                            ></i>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}

export default FormsList