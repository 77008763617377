import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createForm, updateForm, deleteForm, fetchWebhookData, saveSelectedFields, fetchFormsForWorkspace, processWebhookData } from './FormAPI';

// Async thunk for creating a form
export const createFormAsync = createAsyncThunk(
    'form/createForm',
    async (formData) => {
        const response = await createForm(formData);
        return response.data;
    }
);

// Async thunk for updating a form
export const updateFormAsync = createAsyncThunk(
    'form/updateForm',
    async ({ formId, updatedData }) => {
        const response = await updateForm({ formId, updatedData });
        return response.data;
    }
);

export const deleteFormAsync = createAsyncThunk(
    'form/deleteForm',
    async ({ formId, webhookId }) => {
        const response = await deleteForm(formId, webhookId);
        return { ...response.data, formId };  // Include the formId in the returned payload
    }
);

export const fetchWebhookDataAsync = createAsyncThunk(
    'form/fetchWebhookData',
    async (webhookUniqueId) => {
        const response = await fetchWebhookData(webhookUniqueId);
        return response.data;
    }
);

// Async thunk for fetching forms for a workspace
export const fetchFormsForWorkspaceAsync = createAsyncThunk(
    'form/fetchFormsForWorkspace',
    async (workspaceId) => {
        const response = await fetchFormsForWorkspace(workspaceId);
        return response.data;
    }
);

export const saveSelectedFieldsAsync = createAsyncThunk(
    'form/saveSelectedFields',
    async (selectedFields) => {
        const response = await saveSelectedFields(selectedFields);
        return response.data;
    }
);

// Async thunk for processing webhook data
export const processWebhookDataAsync = createAsyncThunk(
    'form/processWebhookData',
    async ({ uniqueId, webhookData }, thunkAPI) => {
        const response = await processWebhookData(uniqueId, webhookData);
        return response.data;
    }
);

const formSlice = createSlice({
    name: 'form',
    initialState: {
        form: null,
        forms: [],
        webhookUniqueId: null,
        status: 'idle',
        error: null,
        webhookData: null,
        spreadsheetId: null,
        webhookStatus: 'idle',
        webhookError: null,
        saveFieldsStatus: 'idle',
        saveFieldsError: null
    },
    reducers: {
        resetForm: (state) => {
            state.form = null;
            state.forms = [];
            state.webhookUniqueId = null;
            state.status = 'idle';
            state.error = null;
            state.webhookData = null;
            state.webhookStatus = 'idle';
            state.webhookError = null;
            state.saveFieldsStatus = 'idle';
            state.saveFieldsError = null
            state.spreadsheetId = null;
        },
        resetPartialForm: (state) => {
            state.form = null;
            state.webhookUniqueId = null;
            state.status = 'idle';
            state.error = null;
            state.webhookData = null;
            state.webhookStatus = 'idle';
            state.webhookError = null;
            state.saveFieldsStatus = 'idle';
            state.saveFieldsError = null
        },
        setFormData: (state, action) => {
            state.form = action.payload.form;
            state.webhookUniqueId = action.payload.webhookUniqueId;
            state.spreadsheetId = null;
        },
        resetWebhookStatus: (state) => {
            state.webhookStatus = 'idle';
        }
    },
    extraReducers: (builder) => {
        builder
            // Create form cases
            .addCase(createFormAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createFormAsync.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.form = action.payload.form;
                state.webhookUniqueId = action.payload.webhookUniqueId;
                state.error = null;
                // Push the newly created form into the forms array
                state.forms.push(action.payload);
            })
            .addCase(createFormAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // Update form cases
            .addCase(updateFormAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateFormAsync.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.form = action.payload.form;
                // Find the index of the form in the forms array
                const index = state.forms.findIndex(form => form.form._id === action.payload.form._id);
                if (index !== -1) {
                    // Update the form in the forms array
                    state.forms[index].form = action.payload.form;
                }
                state.error = null;
            })
            .addCase(updateFormAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchWebhookDataAsync.pending, (state) => {
                state.webhookStatus = 'loading';
            })
            .addCase(fetchWebhookDataAsync.fulfilled, (state, action) => {
                state.webhookStatus = 'succeeded';
                state.webhookData = action.payload;
            })
            .addCase(fetchWebhookDataAsync.rejected, (state, action) => {
                state.webhookStatus = 'failed';
                state.webhookError = action.error.message;
            })
            .addCase(saveSelectedFieldsAsync.pending, (state) => {
                state.saveFieldsStatus = 'loading';
            })
            .addCase(saveSelectedFieldsAsync.fulfilled, (state, action) => {
                state.saveFieldsStatus = 'succeeded';
                state.form.selectedNameField = action.payload.form.selectedNameField;
                state.form.selectedEmailField = action.payload.form.selectedEmailField;
                state.form.selectedFieldsForDecision = action.payload.form.selectedFieldsForDecision;
            })
            .addCase(saveSelectedFieldsAsync.rejected, (state, action) => {
                state.saveFieldsStatus = 'failed';
                state.saveFieldsError = action.error.message;
            })
            .addCase(fetchFormsForWorkspaceAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFormsForWorkspaceAsync.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.forms = action.payload;
            })
            .addCase(fetchFormsForWorkspaceAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // Delete form cases
            .addCase(deleteFormAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteFormAsync.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Remove the deleted form from the forms array
                state.forms = state.forms.filter(form => form.form._id !== action.payload.formId);
                state.error = null;
            })
            .addCase(deleteFormAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(processWebhookDataAsync.pending, (state) => {
                state.webhookStatus = 'loading';
            })
            .addCase(processWebhookDataAsync.fulfilled, (state, action) => {
                state.webhookStatus = 'succeeded';
                state.spreadsheetId = action.payload.spreadsheetId;
            })
            .addCase(processWebhookDataAsync.rejected, (state, action) => {
                state.webhookStatus = 'failed';
                state.webhookError = action.error.message;
            });
    }
});

export const { resetForm, resetPartialForm, setFormData, resetWebhookStatus } = formSlice.actions;
export default formSlice.reducer;