import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWorkspaces } from '../../features/workspace/workspaceSlice';
import WorkSpaces from '../../components/WorkSpaces/WorkSpaces';

const Dashboard = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.workspace.loading);

  useEffect(() => {
    if (loading === 'idle') {
      dispatch(fetchWorkspaces());
    }
  }, [loading, dispatch]);
  return (
    <div>
      {loading === 'loading' ? (
        <p>Loading...</p>
      ) : (
        <>
          <WorkSpaces/>
        </>
      )}
    </div>
  );
}

export default Dashboard;