import React from 'react';
import { Link } from 'react-router-dom';

const Policy = () => {
    return (
        <div className="container" style={{ paddingBottom: "70px", paddingTop: "50px" }}>
            <div className="settings-header mb-4">
                <h1 style={{ fontWeight: "bold" }}>BELLIO DIGITAL PRIVACY POLICY</h1>
            </div>
            <div className="privacy-policy-container" >
                <p>Welcome to Bellio Digital's Privacy Policy. Your privacy is important to us, and we are committed to protecting and respecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our products, services, and technologies (collectively referred to as the "Services").
                    By accessing or using our Services, you agree to the practices described in this Privacy Policy. Please take the time to read this document carefully to understand how we handle your information. If you do not agree with the terms outlined herein, please refrain from using our Services.
                    This Privacy Policy is an integral part of our Terms of Service, and both documents should be read together to comprehend the full scope of our practices. We may update this Privacy Policy from time to time, so we encourage you to check back periodically for any changes. Any updates will be effective when posted, and your continued use of the Services after such changes will constitute your acceptance of the revised Privacy Policy.
                </p>
                {/* Information Collected & Tracking Technologies Used */}
                <h2>1. Information Collected & Tracking Technologies Used</h2>
                <p>At Bellio Digital, transparency and respect for your privacy are foundational to our values. This section outlines the types of information we collect and the technologies we employ to enhance your experience while using our platform.</p>
                {/* Subsections */}
                <h4>1.1. Information Collected:</h4>
                <p>a. Account Information: To provide you with our services, we collect basic account information, including your name, email address, and other details necessary for account creation and management.</p>
                <p>b. Client Workspaces Data: When you create workspaces for managing clients, websites, and product information, we collect data you input into these workspaces. This may include client names, project details, and associated content relevant to the workspace's purpose.</p>
                <p>c. Google Workspace Integration: To enable seamless collaboration, we may access and integrate with your Google Workspace. This integration involves the collection of data, such as Gmail, Google Drive, and Google Docs content, based on the permissions granted during Google OAuth registration.</p>

                <h4>1.2. Tracking Technologies Used:</h4>
                <p>As you navigate through and interact with our Services, we may use automatic data collection technologies including Google Analytics to gather certain information about your equipment, browsing actions and patterns. This is generally statistical data that does not specifically identify a single user. The technologies used include:</p>
                <p>a. Cookies: We utilize cookies to enhance your browsing experience and personalize content based on your preferences. Cookies also help us understand how you interact with our platform, allowing us to improve and tailor our services.</p>
                <p>b. Log Files - Log file information is automatically reported by your browser and/or mobile application each time you access our Services. These server logs may include information such as web requests, IP address, browser type, pages viewed etc.</p>
                <p>c. Analytics: We employ analytics tools to gather data on user behavior, platform usage, and trends. This information is anonymized and used to enhance our platform's functionality, identify areas for improvement, and optimize user experiences.</p>
                <p>d. Web Beacons: Occasionally, we may use web beacons or similar tracking technologies to collect data on user interactions with our emails and platform. This helps us analyze the effectiveness of our communications and improve our services.</p>

                {/* Information Collected & Tracking Technologies Used */}
                <h2>2. USE & PROCESSING OF INFORMATION</h2>
                <p>At Bellio Digital, we are committed to ensuring the responsible use and processing of your information. This section outlines how we utilize the data we collect to provide you with a seamless and personalized experience on our platform.</p>
                <h4>2.1. Purpose of Information Use:</h4>
                <p>a. Service Delivery: We use the information you provide to deliver and enhance the functionality of our Services, including personalized features, collaboration tools, and access to integrated services like Google Workspace and Drive.</p>
                <p>b. Communication: Your contact information may be used to communicate important updates, announcements, or respond to inquiries related to your account or our Services.</p>
                <p>c. Analytics and Improvements: We analyze aggregated data to gain insights into user behavior, platform usage, and trends. This information helps us continually improve our platform, identify areas for enhancement, and tailor our services to meet user needs.</p>
                <h4>2.2. Processing of Information:</h4>
                <p>a. Google Services Integration: Information obtained through integration with Google services is processed to enable seamless collaboration and access to features such as Google Workspace and Drive. This includes permissions necessary for proper functionality.</p>
                <p>b. Automated Responses and AI: In certain instances, we utilize automated responses powered by artificial intelligence (AI) to enhance user interactions, particularly in web form instances. These automated responses are generated based on AI analysis and provided context.</p>
                <p>c. Data Retention: We retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy. The retention period may vary based on legal requirements, contractual obligations, and the nature of the data</p>
                <p>d. Marketing & Communication: With your consent, we may use contact information to send marketing or promotional materials related to our Services. You can unsubscribe from such communication anytime. We do not sell or rent your personally identifiable data.</p>
                {/* DISCLOSURE TO THIRD PARTIES */}
                <h2>3. DISCLOSURE TO THIRD PARTIES</h2>
                <p>At Bellio Digital, transparency is a core value, and we want to ensure you understand how your information may be shared with third parties. This section specifically outlines the disclosure of data, including details about sharing data with Google services and third-party collaborators.</p>
                <h4>3.1. Google Services Data Sharing:</h4>
                <p>a. Integration with Google Services: Bellio Digital seamlessly integrates with Google services, such as Google Workspace and Drive, to provide enhanced collaboration features. By using our platform, you acknowledge and agree that certain data, including account information and content, may be shared with Google as part of this integration.</p>
                <p>b. Permissions and Access: To enable this integration, necessary permissions are sought from you during account setup. This includes permissions required to access your Google account information, collaborate on Google Drive, and utilize other Google services within the Bellio Digital platform.</p>
                <p>c. Compliance with Google Policies: Data shared with Google is subject to Google's terms and policies. Users are encouraged to review Google's privacy policies and terms of service to understand how their information is handled within the integrated Google services.</p>
                <h4>3.2. Third-Party Collaborators:</h4>
                <p>a. Limited Data Sharing: We may share your information with third-party collaborators to the extent necessary for providing and improving our Services. This includes external partners, service providers, or entities collaborating with Bellio Digital on specific features or enhancements.</p>
                <p>b. Enhancements and Feature Development: Data shared with third parties is done with the primary aim of enhancing our platform's features, improving functionality, or collaborating on initiatives that benefit our users. Any such collaboration is conducted in compliance with applicable data protection laws.</p>
                <h4>3.3. Data Security and Confidentiality:</h4>
                <p>a. Security Measures: We take significant measures to ensure the security and confidentiality of your data when shared with third parties. This includes enforcing data protection agreements and regularly assessing the security practices of our collaborators.</p>
                <p>b. Confidentiality Commitment: Bellio Digital does not sell or trade your personal information to third parties for their marketing purposes. Data shared is strictly used for collaborative purposes in line with the functionality of our Services.</p>
                <h2>4. ADVERTISING/MARKETING</h2>
                <p>At Bellio Digital, we strive to provide you with a tailored and relevant user experience. This section outlines our advertising and marketing practices, including the disclosure of Google Ads usage and targeting features.</p>
                <h4>4.1. Google Ads Usage:</h4>
                <p>a. Display Advertising: We may use Google Ads features, including but not limited to Remarketing with Google Analytics, to display relevant advertisements to users across the internet. This enables us to reach users who have previously visited our platform and tailor our marketing messages.</p>
                <p>b. Google Analytics: Bellio Digital may utilize Google Analytics to better understand user behavior, improve our platform, and deliver targeted advertising. Google Analytics may collect information about your browsing history and interactions with our platform to enhance the relevancy of our advertisements.</p>
                <h4>4.2. Targeting Features:</h4>
                <p>a. Demographic Targeting: Our advertising efforts, including those through Google Ads, may involve demographic targeting to ensure that our messages reach specific audience segments. This may include targeting based on age, gender, location, and other demographic factors.</p>
                <p>b. Interest-Based Targeting: We may engage in interest-based targeting, leveraging insights from your interactions with our platform to deliver advertisements aligned with your interests. This helps create a more personalized and engaging advertising experience.</p>
                <h4>4.3. Opt-Out Options:</h4>
                <p>a. Google Ad Settings: Users have the option to control ad personalization through their Google Ad Settings. This allows you to manage how your information is used for ad targeting across the Google Ads network.</p>
                <p>b. Cookie Preferences: You can manage cookie preferences through your browser settings to control the information collected for advertising purposes. Limiting certain tracking technologies may impact the effectiveness of our targeted advertising.</p>
                <h4>4.4. Transparency and Privacy:</h4>
                <p>a. Privacy Commitment: Bellio Digital is committed to safeguarding your privacy. While our advertising practices aim to enhance your user experience, we prioritize transparency and provide you with the means to control the extent to which your data is used for advertising purposes.</p>
                <p>b. No Selling of Personal Information: We do not sell your personal information to third parties for their marketing purposes. Our advertising and marketing efforts are focused on delivering a relevant and personalized experience within the boundaries of your privacy preferences. The insights allow more relevant Bellio Digital promotions to display across websites you visit. Control options are available at https://adssettings.google.com.</p>
                <h2>5. DATA RETENTION</h2>
                <p>At Bellio Digital, we are committed to maintaining the appropriate and secure retention of your data. This section outlines our data retention practices, including specific considerations for data associated with Google services.</p>
                <h4>5.1. General Data Retention:</h4>
                <p>a. User Account Information: We retain basic user account information, such as your name and email address, for as long as your account is active. This enables us to provide continuous access to our Services and personalized features. In cases of voluntary termination or account inactivity triggering automated deletion, core data like name, email, phone and transaction records may still be retained for up to 5 years in compliance with various regulations regarding customer data, contracts, accounting and auditing obligations.</p>
                <p>b. Client Workspaces Data: Data associated with client workspaces, including websites, product details, and collaborative content, may be retained for the duration of your use of the platform. This facilitates ongoing collaboration and access to historical information.</p>
                <h4>5.2. Google Service Data Retention:</h4>
                <p>a. Google Workspace and Drive Integration: Data integrated with Google services, including Google Workspace and Drive, may be subject to Google's data retention policies. Users are encouraged to review Google's terms and policies to understand the specific retention periods applicable to data shared with these integrated services. Bellio Digital does not enforce separate retention rules on Google content once access is revoked or a linked account is deleted by you the user per standard deletion practices.</p>
                <p>b. Compliance with Google Policies: Bellio Digital adheres to Google's terms and policies regarding data retention. While we strive to align our practices with industry standards, users should be aware that certain data shared with Google services may be governed by Google's own retention periods.</p>
                <h4>5.3. User Control and Deletion:</h4>
                <p>a. Account Termination: Users have the option to terminate their accounts at any time, which will initiate the process of deactivating their account and, where applicable, removing associated data from our platform.</p>
                <p>b. Google Service Permissions Revoke: Revoking permissions for Google services within your account settings may limit the retention of new data shared with these services. However, existing data previously shared with Google services may be subject to the retention periods defined by Google.</p>
                <h2>6. CHANGES TO PRIVACY POLICY</h2>
                <p>At Bellio Digital, we are dedicated to maintaining transparency and keeping you informed about how we handle your data. This section outlines our approach to updating the Privacy Policy, with specific consideration for changes that may be necessary due to updates or modifications in Google services.</p>
                <h4>6.1. Policy Modification and Notification:</h4>
                <p>a. Regular Review: We regularly review and update our Privacy Policy to reflect changes in our practices, legal requirements, and the evolving landscape of online services.</p>
                <p>b. Notification of Changes: In the event of updates or modifications to the Privacy Policy, we will notify users through the Services, via email, or through other means deemed appropriate. It is essential to review any changes to stay informed about how your data is handled.</p>
                <h4>6.2. Updates Related to Google Services:</h4>
                <p>a. Integration Considerations: Updates to Google services, including Google Workspace and Drive, may necessitate adjustments to our Privacy Policy to align with the latest data handling practices and policies of these integrated services.</p>
                <p>b. Continued Compliance: We are committed to ensuring ongoing compliance with Google's terms and policies. Any changes to our Privacy Policy related to Google services will be made to maintain alignment with industry standards and user expectations.</p>
                <h4>6.3. User Acceptance and Opt-Out Options:</h4>
                <p>a. Acceptance of Modified Terms: By continuing to use our Services after modifications to the Privacy Policy have been posted, users agree to be bound by the updated terms. If users do not agree with the modified terms, they must cease using the Services.</p>
                <p>b. Opt-Out Options: Users who do not agree with the modified Privacy Policy, particularly changes related to Google services, have the right to reject them. This may result in limitations on certain features or the discontinuation of specific services.</p>
                <h4>6.4. Contact Information Update:</h4>
                <p>a. Maintaining Current Contact Information: Users must ensure that their contact information, including email addresses associated with their account, is accurate and up-to-date to receive notifications of modifications to the Privacy Policy.</p>
                <p>By using Bellio Digital, you acknowledge and agree to the potential changes to the Privacy Policy, particularly those related to updates in Google services, as outlined in this section. For a comprehensive understanding of our data handling practices, please refer to our Privacy Policy.</p>
                {/* <h2>7. Compliance with Google Policies</h2>
                <p>At Bellio Digital, we are dedicated to upholding the highest standards of data privacy and security. This section emphasizes our commitment to compliance with Google's policies, particularly the API Services User Data Policy.</p>
                <h4>7.1. Google Policies Integration:</h4>
                <p>a. Adherence to Google Policies: Bellio Digital integrates with Google services, including Google Workspace and Drive. We commit to full compliance with Google's policies, terms of service, and any specific guidelines set forth by Google related to user data.</p>
                <p>b. API Services User Data Policy: Users are encouraged to familiarize themselves with Google's API Services User Data Policy, which outlines the expectations and guidelines for the use and protection of user data within the context of API services.</p>
                <h4>7.2. User Data Protection Commitment:</h4>
                <p>a. Security and Confidentiality: Our commitment extends to the security and confidentiality of user data integrated with Google services. We implement robust measures to safeguard this data against unauthorized access, disclosure, or any form of misuse.</p>
                <p>b. Data Handling Practices: We adhere to industry best practices in data handling, ensuring that user data is treated with the utmost care and used only for the purposes outlined in our Privacy Policy and in compliance with Google's policies.</p>
                <h4>7.3. User Consent and Permissions:</h4>
                <p>a. Explicit User Consent: Users are informed and explicitly consent to the permissions required for the integration with Google services. This includes necessary access to user data within Google Workspace and Drive for the seamless functioning of our platform.</p>
                <p>b. Revocation of Permissions: Users retain the right to revoke permissions granted for the integration with Google services at any time. Revoking permissions may impact certain features and functionalities, and users will be duly informed of the consequences.</p> */}
                <h2>7. Compliance with Google Policies</h2>
                <h4 className='pt-2'>7.1. Google Policies Integration:</h4>
                <p>a. API Services User Data Policy: Bellio Digital AI’s use and transfer of information received from Google APIs to any other app will adhere to the <a href="https://developers.google.com/terms/api-services-user-data-policy" style={{color: "#0700DD"}} target='_blank'>Google API Services User Data Policy</a>, including the <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" style={{color: "#0700DD"}} target="_blank">Limited Use requirements.</a></p>
                <h4>7.2. User Data Protection Commitment:</h4>
                <p>a. Security and Confidentiality: Our commitment extends to the security and confidentiality of user data integrated with Google services. We implement robust measures to safeguard this data against unauthorized access, disclosure, or any form of misuse.</p>

                {/* Contact Us Section */}
                <h2>Contact Us</h2>
                <p>We value your feedback, questions, and concerns. If you need assistance, want to report an issue, or have any inquiries regarding our platform, please feel free to reach out to us. Our dedicated support team is here to help.</p>
            </div>
        </div>
    );
}

export default Policy;