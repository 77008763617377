import React from 'react';
import { Link } from 'react-router-dom';

const Support = () => {
    return (
        <div className="container mt-4">
            <div className="settings-header mb-4">
                <h2>Help & Support</h2>
            </div>
            <div className="support-sections">
                {supportLinks.map((link, index) => (
                    <Link 
                        to={link.path} 
                        className="support-link" 
                        key={index}
                        style={{
                            display: 'block',
                            padding: '20px',
                            marginBottom: '20px',
                            backgroundColor: 'white',
                            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
                            borderRadius: '5px',
                            border: '1px solid #ddd',
                            textDecoration: 'none',
                            color: 'black'
                        }}
                    >
                        <h4>{link.title}</h4>
                        <p>{link.description}</p>
                    </Link>
                ))}
            </div>
        </div>
    );
}

const supportLinks = [
    {
        path: "#",
        title: "Frequently Asked Questions (FAQs)",
        description: "Find answers to commonly asked questions."
    },
    {
        path: "#",
        title: "User Guide",
        description: "Step-by-step instructions on how to use the application."
    },
    {
        path: "#",
        title: "Video Tutorials",
        description: "Watch tutorials for a visual guide on using the app."
    },
    {
        path: "#",
        title: "Contact Support",
        description: "Need more help? Reach out to our support team."
    },
    {
        path: "#",
        title: "Feedback Form",
        description: "Share your feedback, report bugs, or suggest new features."
    }
];

export default Support;