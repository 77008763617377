import api from "../../helpers/api";

export const signup = async (userData) => {
  return api.post("/api/auth/signup", userData);
};

export const activateAccountAPI = (token) => {
  return api.get(`/api/auth/activate-account?token=${token}`);
};

export const signin = (userData) => {
  return api.post("/api/auth/signin", userData);
};

export const logoutAPI = () => {
  return api.post("/api/auth/logout");
};

export const updateProfile = (userData) => {
  return api.patch("/api/user/update-profile", userData);
};

export const changePassword = (passwordData) => {
  return api.patch("/api/user/change-password", passwordData);
};

export const fetchUserAPI = () => {
  return api.get("/api/user");
};

export const forgotPasswordAPI = (email) => {
  return api.post("/api/auth/forgot-password", { email });
};

export const resetPasswordAPI = ({ token, password }) => {
  return api.post("/api/auth/reset-password", { token, password });
};

export const fetchCsrfToken = () => {
  return api.get("/api/auth/token");
};
export const fetchAllowedDomains = () => {
  return api.get("/api/test/allowed-destinations");
};
