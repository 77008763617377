import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { createFormAsync, updateFormAsync } from '../../features/form/formSlice';

const validationSchema = Yup.object({
    formName: Yup.string()
        .required('Form name is required')
});

const Step1 = ({ nextClick, prevClick, closeClick }) => {
    const dispatch = useDispatch();
    const form = useSelector(state => state.form.form);
    const status = useSelector(state => state.form.status);
    const { workspaceId } = useParams();

    const formik = useFormik({
        initialValues: {
            formName: form ? form.name : ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (form) {
                // If a form already exists, update it
                dispatch(updateFormAsync({ formId: form._id, updatedData: { name: values.formName } }))
                    .then((response) => {
                        if (response.type === updateFormAsync.fulfilled.type) {
                            nextClick();
                        }
                    });
            } else {
                // If no form exists, create a new one
                dispatch(createFormAsync({ name: values.formName, workspaceId }))
                    .then((response) => {
                        if (response.type === createFormAsync.fulfilled.type) {
                            nextClick();
                        }
                    });
            }
        }
    });

    return (
        <div className="multisteps-form__panel pl-2 pr-2 rounded bg-white js-active" data-animation="scaleIn">
            <div className="multisteps-form__content">
                <h3 className='mb-3 mt-3'>Form Name</h3>
                <div className="form-row mt-4">
                    <div className="col-12 col-sm-12">
                        <label className="form-label" htmlFor="formName">Enter Form Name</label>
                        <input
                            className="multisteps-form__input form-control"
                            type="text"
                            id="formName"
                            name="formName"
                            placeholder="Enter Form Name"
                            value={formik.values.formName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                        />
                        {formik.touched.formName && formik.errors.formName ? (
                            <div className="text-danger">{formik.errors.formName}</div>
                        ) : null}
                    </div>
                </div>
                <div className="button-row d-flex mt-3 pb-3">
                    <div className="ml-auto">
                        <button
                            className="btn btn-primary popup-button"
                            onClick={formik.handleSubmit}
                            disabled={status === 'loading'}
                        >
                            {status === 'loading' ? 'Loading...' : 'Next'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Step1;