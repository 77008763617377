import React from 'react';
import { useSelector } from 'react-redux';
import celebrate from '../../assets/images/celebrate.png';

const Step3 = ({ nextClick, prevClick }) => {
    const webhookData = useSelector(state => state.form.webhookData);
    const webhookUniqueId = useSelector(state => state.form.webhookUniqueId);
    const webhookUrl = `${process.env.REACT_APP_FRONTEND_URL}/webhook/${webhookUniqueId}`;

    return (
        <div className="multisteps-form__panel pl-2 pr-2 rounded bg-white js-active" data-animation="scaleIn">
            <div className="multisteps-form__content">
                <h3 className='mb-3 mt-3'>Status</h3>
                <div className="form-row mt-4">
                    <div className="col-12 col-sm-12">
                        <div className="text-center mt-3">
                            <h2 className="mt-3">We've got a request!</h2>
                            <img src={celebrate} width="60" alt="Celebration" />
                        </div>
                        <p>This request was sent to:</p>
                        <p className="mb-3">
                            <strong>{webhookUrl}</strong>
                        </p>
                        <div className="row">
                            <div className="col-lg-12">
                                <h5>Received Data</h5>
                                <ul style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                    {Object.entries(webhookData).map(([key, value]) => (
                                        <li key={key}><strong>{key}:</strong> {value}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="button-row d-flex mt-3 pb-3">
                <div className="ml-auto">
                    <button className="btn btn-secondary mr-2 popup-button" onClick={prevClick}>
                        Previous
                    </button>
                    <button className="btn btn-primary popup-button" onClick={nextClick}>Next</button>
                </div>
            </div>
        </div>
    )
}

export default Step3;