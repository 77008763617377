import axios from "axios";
import { store, persistor } from "../app/store";
import { logout, resetAuth } from "../features/auth/authSlice";
import { resetWorkspaceStatus } from "../features/workspace/workspaceSlice";
import { resetProductServiceStatus } from "../features/productService/productServiceSlice";
import { resetForm } from "../features/form/formSlice";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 120000,
  withCredentials: true,
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    // Adding custom headers here
    config.headers["csrf-token"] = localStorage.getItem("csrfToken");

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // If a 401 response is received, dispatch the appropriate actions to Redux
      store.dispatch(logout());
      store.dispatch(resetAuth());
      store.dispatch(resetForm());
      store.dispatch(resetProductServiceStatus());
      store.dispatch(resetWorkspaceStatus());
      persistor.purge(); // clearing the persisted state
    }
    return Promise.reject(error);
  }
);

export default api;
