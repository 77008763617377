import React from 'react';
import { Link } from 'react-router-dom';

const Terms = () => {
    return (
        <div className="container" style={{ paddingBottom: "70px", paddingTop: "50px" }}>
            <div className="settings-header mb-4">
                <h1 style={{ fontWeight: "bold" }}>BELLIO DIGITAL TERMS OF SERVICE</h1>
            </div>
            <div className="privacy-policy-container" >
                <p>These Terms of Service ("Terms") govern the access to and use of the products, services, and technologies (collectively, the "Services") provided by Bellio Digital Inc. ("Bellio Digital", "we", "our", or "us"). These Terms constitute a legally binding agreement between the user of the Services ("you" or "your") and Bellio Digital regarding your use of the Services.</p>
                <p>Please read these Terms carefully before accessing or using any of our Services. By accessing the Services, you agree to be bound by these Terms as well as our Privacy Policy. If you do not agree to all of these Terms, do not access or use any of the Services.</p>
                <p>In order to use the Services, you must be at least 18 years of age or older, as well as legally competent to enter into a contract. If you are under 18 but at least 13 years of age, your parent or legal guardian must review these Terms and agree to them on your behalf.</p>
                <p>These Terms cover important details including requirements around account registrations and termination, subscription plans and billing, appropriate use policies, intellectual property protections, disclaimers, liabilities, and more related to use of Bellio Digital's Services. Please review the Terms fully prior to use of our Services to ensure you understand and consent.</p>

                {/* 1. DEFINITIONS */}
                <h2>1. DEFINITIONS</h2>
                <p>a. "Account" refers to the user's account created for accessing and using the Services.</p>
                <p>b. "Bellio Digital," "we," "our," or "us" refers to Bellio Digital Inc., the entity providing the Services.</p>
                <p>c. "Content" any text, images, videos, or other materials provided or made accessible through the Services.</p>
                <p>d. "Effective Date" signifies the date on which these Terms become applicable, commencing upon your acceptance.</p>
                <p>e. "Google Services" includes any integrated Google offering including Google Workspace, Google Drive, Google OAuth, Google APIs, Google Ads and related advertising services. Use of Google Services requires compliance with all applicable Google policies.</p>
                <p>f. "Intellectual Property" includes trademarks, copyrights, patents, and other proprietary rights.</p>
                <p>g. "Privacy Policy" refers to Bellio Digital's privacy policy, outlining how user information is collected, used, and protected.</p>
                <p>h. "Services" refers to the products, services, technologies, features, integrations, and offerings provided by Bellio Digital to users as part of our platform and applications whether as a free or paid subscription. This includes use of our website, mobile and desktop applications and access to any included third party applications we provide.</p>
                <p>i. "Subscription" refers to the arrangement for ongoing access to specific features or services within the Services, subject to applicable fees.</p>
                <p>j. "Termination" denotes the conclusion of the user's right to access or use the Services, either voluntarily or due to violation of these Terms.</p>
                <p>k. "Terms" refer to these Terms of Service, constituting a legally binding agreement between you and Bellio Digital regarding the use of the Services.</p>
                <p>l. "Third-Party Services" refers to services provided by entities other than Bellio Digital, accessible through the Services.</p>
                <p>m. "User Content" means any material, data or content submitted by Users to the Services such as emails, documents, forms, workspaces, client data, or other information.</p>
                <p>n. "User Content" pertains to any content, including text or media, provided by the user through the Services.</p>
                <p>o. "User", "You", refers to any person or entity that accesses or uses the Services.</p>
                <p>p. "You" or "your" refers to the user of the Services, whether as an individual or on behalf of an entity.</p>
                {/* 2. ACCOUNT REGISTRATION/TERMINATION */}
                <h2>2. ACCOUNT REGISTRATION/TERMINATION</h2>
                <p>Registration for a user account enables access to the Services provided by Bellio Digital. There are two options available for registering for an account:</p>
                <h4>2.1 Account Registration</h4>
                <p>a. Google OAuth Registration:  Users can register and authenticate through the integration with Google OAuth. By opting for Google OAuth registration, you grant Bellio Digital authorization to access specific Google account information and services, as approved through the Google consent screen. Accessible information may include your Google profile name, email address, contacts, and other integrated Google Workspace content, such as Gmail, Google Drive, and Google Docs. The registered information and associated access tokens will be utilized to establish your user profile and facilitate core Service functionalities.</p>
                <p>b. Email Registration: Alternatively, users can register by providing their email address and creating a password. The email and password serve as the login credentials necessary to access the Services. Users agree to provide accurate information during the traditional registration process. The email and any additional information collected during signup will be used solely for account creation, management, and the delivery of core Services.</p>
                <h4>2.2 Responsibilities for All Account Types</h4>
                <p>a. Regardless of registration method, you are fully responsible for maintenance and security of your account credentials as well as any activities that occur under your account. You agree to immediately notify Bellio Digital of any unauthorized use of your account. Account sharing is prohibited.</p>
                <p>b. Bellio Digital retains ownership of all registered accounts on the platform and reserves the right to monitor usage and content for compliance purposes. Additional details are outlined in our Privacy Policy.</p>
                <h4>2.3 Account Termination</h4>
                <p>a. Users can request account termination at any time by submitting a cancellation request via email or by canceling any active subscription renewals through their account dashboard. This action will revoke access to the Services through the account.</p>
                <p>b. Bellio Digital reserves the right to suspend or terminate any account for violations of these Terms of Service, extended periods of inactivity, disruptive behavior, harassment, illegal activities, or the inability to process subscription payments.</p>
                <p>c. Upon termination, Bellio Digital may deactivate or destroy content in registered accounts, and any pending tasks or transactions may be forfeited. Refund eligibility will be determined at the sole discretion of Bellio Digital. Termination does not absolve users of responsibility for any outstanding fees or charges incurred.</p>
                <h2>3. USE OF GOOGLE SERVICES</h2>
                <p>By utilizing the Services provided by Bellio Digital, you acknowledge and agree to the integration with Google Services, including but not limited to Google Workspace and Google Drive. This integration enhances the functionality of our Services, providing you with seamless access to various tools and features. Please read this section carefully to understand how the integration with Google Services may impact your business operations.</p>
                <h4>3.1 Google OAuth Integration</h4>
                <p>a. Authorization: By opting for Google OAuth registration, you authorize Bellio Digital to access specific Google account information and services. This authorization includes, but is not limited to, your Google profile name, email address, contacts, and other relevant information as approved through the Google consent screen.</p>
                <p>b. Google Workspace Integration: Bellio Digital may access and integrate with your Google Workspace, including Gmail, Google Drive, and Google Docs. This integration is designed to streamline your workflow, allowing you to manage your business communications, documents, and collaborative efforts seamlessly through our Services.</p>
                <h4>3.2 Business Data Security</h4>
                <p>a. Data Protection: Bellio Digital is committed to the security and protection of your business data. We implement industry-standard security measures to safeguard the information accessed through Google Services. However, it's important to note that the security of your Google account credentials and data shared through the Services is a shared responsibility. Please ensure the confidentiality of your login credentials and report any unauthorized access immediately.</p>
                <p>b. Permissions and Access: To provide the intended Services, Bellio Digital requires certain permissions and access to your Google Workspace. These permissions are strictly limited to what is necessary for the proper functioning of our Services. We do not store or retain your Google account credentials beyond the scope of the required access.</p>
                <h4>3.3 Compliance with Google Policies</h4>
                <p>a. API Services User Data Policy: Bellio Digital is committed to complying with Google's API Services User Data Policy. This policy outlines the expectations and responsibilities concerning the use of Google services through third-party applications, ensuring that user data is handled securely and in accordance with Google's standards.</p>
                <p>b. Data Usage Transparency: Bellio Digital is transparent about the data accessed and utilized from your Google account. Our Privacy Policy provides detailed information on the types of data collected, how it is used, and the purposes for which it is processed.</p>
                <h2>4. SUBSCRIPTION FEES & BILLING</h2>
                <p>By subscribing to services featuring Google products offered by Bellio Digital, you agree to the following terms related to subscription fees and billing. Please carefully read this section to understand the financial aspects of your subscription.</p>
                <h4>4.1 Subscription Plans</h4>
                <p>a. Billing Periods: Subscription plans are available for various billing periods, such as monthly, annually, or as otherwise specified. The billing period applicable to your subscription will be clearly communicated during the subscription process.</p>
                <p>b. Service Features: Different subscription plans may offer varying features, including access to specific Google products and enhanced functionalities within the Services. Please review the details of your chosen subscription plan to understand the features included.</p>
                <h4>4.2 Google Product Integration Fees</h4>
                <p>a. Additional Fees: Some subscription plans may include access to premium Google products, and additional fees may apply based on the usage or scale of integration with these products. These fees will be transparently communicated, and you will be informed in advance of any additional charges.</p>
                <p>b. Usage-Based Charges: If your subscription involves usage-based charges related to Google products, you will be billed according to the applicable usage metrics. Details of usage-based charges will be outlined in your billing statements.</p>
                <h4>4.3 Billing Information</h4>
                <p>a. Payment Method: You agree to provide accurate and up-to-date billing information, including a valid payment method, when subscribing to our services. This information is necessary for the processing of subscription fees.</p>
                <p>b. Auto-Renewal: Subscription plans may auto-renew unless you cancel before the end of the current billing period. The renewal period and associated charges will be specified in your account settings.</p>
                <h4>4.4 Fee Changes and Notifications</h4>
                <p>a. Notification of Changes: In the event of changes to subscription fees or billing practices, Bellio Digital will provide advance notice. Notification may be sent via email or through the Services, ensuring you have sufficient time to review and make informed decisions.</p>
                <p>b. Opt-Out Options: You have the option to opt-out of any changes to subscription fees by canceling your subscription before the changes take effect. Continued use of the Services after fee changes indicate your acceptance of the updated terms.</p>
                <h4>4.5 Refunds and Cancellations</h4>
                <p>a. Refund Policy: Refund eligibility is determined at the sole discretion of Bellio Digital. Refunds may be granted in accordance with our Refund Policy, which is available on our website.</p>
                <p>b. Cancellation Process: You may cancel your subscription at any time through your account dashboard or by contacting our customer support. Cancellation requests must be submitted before the end of the current billing period to avoid automatic renewal.</p>
                <h2>5. ACCEPTABLE USE</h2>
                <p>Your use of the Services provided by Bellio Digital is subject to the following Acceptable Use terms. By accessing or utilizing our Services, you agree to adhere to the guidelines outlined in this section. Any violation of these terms may result in the suspension or termination of your account. Please read this Acceptable Use clause carefully to understand the expectations surrounding your use of our Services.</p>
                <h4>5.1 Prohibited Activities</h4>
                <p>a. Illegal Activities: Users are prohibited from engaging in any activities that violate applicable laws, regulations, or third-party rights. This includes, but is not limited to, engaging in fraudulent activities, distributing malware, or participating in any illegal practices.</p>
                <p>b. Harassment and Abuse: Harassment, abuse, or any form of disruptive behavior towards other users or Bellio Digital's staff is strictly prohibited. This includes, but is not limited to, bullying, hate speech, or any behavior that creates a hostile environment.</p>
                <h4>5.2 Content Guidelines</h4>
                <p>a. Inappropriate Content: Users may not upload, share, or distribute content that is inappropriate, offensive, or violates the rights of others. This includes content that is discriminatory, sexually explicit, or promotes violence.</p>
                <p>b. Intellectual Property Violations: Users must respect intellectual property rights and may not infringe on copyrights, trademarks, or other proprietary rights. Unauthorized use or distribution of copyrighted material is strictly prohibited.</p>
                <h4>5.3 System and Network Integrity</h4>
                <p>a. Security Violations: Users may not attempt to compromise the security or integrity of the Services. This includes, but is not limited to, unauthorized access, interference with system operations, or any activity that may compromise the privacy of other users.</p>
                <p>b. Denial of Service (DoS) Attacks: Any attempt to disrupt the availability or functionality of the Services through DoS attacks or similar activities is strictly prohibited.</p>
                <h4>5.4 Compliance with Policies</h4>
                <p>a. Compliance with Google Policies: Users agree to comply with Google's policies, including but not limited to the API Services User Data Policy, when integrating with Google products through the Services.</p>
                <p>b. Compliance with Terms: Users must comply with all terms and conditions outlined in these Terms of Service. Violation of these terms may result in the suspension or termination of your account.</p>
                <h4>5.5 Reporting Violations</h4>
                <p>a. Reporting: Users are encouraged to report any violations of this Acceptable Use clause or suspected security vulnerabilities promptly to Bellio Digital's support team.</p>
                <p>b. Investigation and Action: Bellio Digital will investigate reported violations and may take appropriate action, including the suspension or termination of accounts, to address any breaches of these terms.</p>
                <h2>6. INTELLECTUAL PROPERTY RIGHTS</h2>
                <p>Your use of the Services provided by Bellio Digital involves the recognition and acknowledgment of the following terms related to intellectual property rights. By accessing or utilizing our Services, you agree to abide by the guidelines outlined in this section. Please carefully read this Intellectual Property Rights clause to understand the permissions and restrictions concerning content, particularly from Google services.</p>
                <h4>6.1 Ownership and Permissions</h4>
                <p>a. Bellio Digital's Ownership: All content, features, and functionalities provided through the Services are the exclusive property of Bellio Digital unless otherwise specified. Bellio Digital retains all rights, title, and interest in and to the Services.</p>
                <p>b. User Content Ownership: Users retain ownership of content they upload or create within the Services. However, by providing content, users grant Bellio Digital a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute the content for the purpose of providing and improving the Services.</p>
                <h4>6.2 Use of Google Services Content</h4>
                <p>a. Permissions from Google Services: Users may access and integrate content from Google services, such as Google Workspace and Google Drive, within the Services. Permissions granted by Google for such content are subject to Google's terms and policies.</p>
                <p>b. Restrictions on Google Services Content: Users must comply with Google's terms and policies regarding the use of content from Google services. Unauthorized use, reproduction, or distribution of Google services content is strictly prohibited.</p>
                <h4>6.3 Third-Party Intellectual Property</h4>
                <p>a. Respecting Third-Party Rights: Users must respect the intellectual property rights of third parties. Unauthorized use or distribution of copyrighted material, trademarks, or other proprietary rights owned by third parties is strictly prohibited.</p>
                <p>b. Reporting Infringements: Users are encouraged to report any suspected intellectual property rights infringements promptly to Bellio Digital's support team.</p>
                <h4>6.4 Trademarks and Branding</h4>
                <p>a. Bellio Digital Trademarks: The trademarks, logos, and service marks displayed within the Services are the property of Bellio Digital. Users are prohibited from using these marks for any purpose without the express written consent of Bellio Digital.</p>
                <p>b. Third-Party Trademarks: The use of trademarks or logos owned by third parties within the Services does not imply endorsement or sponsorship by the respective trademark owners. Users must adhere to third-party trademark policies.</p>
                <h2>7. LIMITATION OF LIABILITY</h2>
                <p>Your use of the Services provided by Bellio Digital is subject to the following Limitation of Liability terms. By accessing or utilizing our Services, you agree to the guidelines outlined in this section. Please carefully read this Limitation of Liability clause to understand the scope of our responsibilities and limitations regarding your use of the Services.</p>
                <h4>7.1 No Warranty of Uninterrupted Service</h4>
                <p>a. Service Availability: Bellio Digital strives to maintain uninterrupted access to the Services. However, we do not guarantee continuous, secure, or error-free operation of the Services. Users acknowledge that temporary disruptions may occur, and Bellio Digital is not liable for any resulting inconvenience or loss.</p>
                <h4>7.2 User Responsibilities</h4>
                <p>a. Data Security: Users are responsible for the security and confidentiality of their account credentials. Bellio Digital is not liable for any unauthorized access to user accounts or breaches of data security resulting from user negligence.</p>
                <p>b. Backup and Data Loss: Users are encouraged to maintain backups of their data. Bellio Digital is not liable for any loss or corruption of user data. Users should take appropriate measures to protect their data and mitigate the risk of loss.</p>
                <h4>7.3 Third-Party Services</h4>
                <p>a. Third-Party Integrations: The Services may integrate with third-party services, and Bellio Digital is not responsible for the availability, functionality, or security of these third-party services. Users use such integrations at their own risk.</p>
                <h4>7.4 Limitation of Direct Damages</h4>
                <p>a. Exclusion of Damages: To the extent permitted by law, Bellio Digital shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
                    i. Your use or inability to use the Services.
                    ii. Any unauthorized access to or use of your account.
                    iii. Any content or conduct of any third party using the Services.
                    iv. Any errors, omissions, or inaccuracies in the content provided through the Services.</p>
                <h4>7.5 Exclusions and Limitations</h4>
                <p>a. Applicable Law: Some jurisdictions may not allow the exclusion or limitation of certain damages. In such cases, the liability of Bellio Digital shall be limited to the fullest extent permitted by applicable law.</p>
                <h4>7.6 No Consequential Damages</h4>
                <p>Under no circumstances shall Bellio Digital be liable for any indirect, consequential, special, incidental, or punitive damages.</p>
                <h4>7.7 Exceptions to Limitations:</h4>
                <p>Nothing in these Terms of Service shall exclude or limit liability for losses which may not be lawfully excluded or limited by applicable law.</p>
                <h2>8. MODIFICATIONS TO TERMS</h2>
                <p>Bellio Digital reserves the right to modify or update these Terms of Service at any time. Users will be notified of any changes through the Services or via email to the address associated with their account. Modifications may include, but are not limited to, changes in fees, service features, or acceptable use policies.</p>
                <h4>8.1 Acceptance of Modifications</h4>
                <p>a. Review of Updated Terms: Users are encouraged to regularly review these Terms to stay informed about any changes. The most current version of the Terms will be available on the Bellio Digital website.</p>
                <p>b. Continued Use as Acceptance: By continuing to use the Services after modifications to the Terms have been posted, users agree to be bound by the updated terms. If users do not agree with the modified terms, they must cease using the Services.</p>
                <h4>8.2 Notification of Changes</h4>
                <p>a. Methods of Notification: Notification of modifications may be provided through the Services, via email, or through other means deemed appropriate by Bellio Digital.</p>
                <p>b. Effective Date of Changes: Changes to the Terms will become effective immediately upon posting, unless otherwise specified. Users are responsible for reviewing the Terms regularly to be aware of any modifications.</p>
                <h4>8.3 Right to Reject Modifications</h4>
                <p>a. User's Right to Reject: If users do not agree with the modified Terms, they have the right to reject them. However, this may result in the suspension or termination of their account.</p>
                <p>b. Cancellation Option: Users who reject the modified Terms may cancel their subscription and terminate their account as outlined in the Cancellation and Termination section of these Terms.</p>
                <h4>8.4 Continuing Compliance</h4>
                <p>Users are responsible for complying with the most current version of the Terms. Bellio Digital is not liable for any consequences resulting from the user's failure to review and accept modified terms.</p>
                <h4>8.5 Contact Information Update</h4>
                <p>a. Maintaining Current Contact Information: Users must ensure that their contact information, including email addresses associated with their account, is accurate and up-to-date to receive notifications of modifications.</p>
                <h2>9. GOVERNING LAW</h2>
                <p>This section outlines the governing law that applies to the use of Bellio Digital's platform and services, specifying Florida as the jurisdiction for legal matters.</p>
                <h4>9.1. Jurisdiction</h4>
                <p>The use of Bellio Digital's platform and services is subject to the laws of the state of Florida, United States. By accessing and utilizing our services, users agree to submit to the jurisdiction of Florida for any legal matters arising from or related to their use of the platform.</p>
                <h4>9.2. Dispute Resolution</h4>
                <p>All disputes arising from or related to this policy shall be brought exclusively in a federal or state court located in Florida. Use of our Services or provision of any information constitutes consent to the exclusive jurisdiction clauses identified in the User Agreement and herein.</p>
                <h4>9.3. Severability:</h4>
                <p>If any provision is determined to be invalid under law, the remaining policies and clauses will still remain enforceable. Please check back regularly for updates to this and other policies that apply to use of Services. Contact us with any questions or concerns regarding data practices and legal compliance.</p>
                {/* Contact Us Section */}
                <h2>Contact Us</h2>
                <p>We value your feedback, questions, and concerns. If you need assistance, want to report an issue, or have any inquiries regarding our platform, please feel free to reach out to us. Our dedicated support team is here to help.</p>
            </div>
        </div>
    );
}

export default Terms;