import React, { useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  signupUser,
  fetchUser,
  resetAuthStatus,
} from "../../features/auth/authSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./form.css";
// import '../../assets/Form/form.css';
import logo from "../../assets/images/Bellio-Digital-AI-Gradient.png";

const RegisterForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authStatus = useSelector((state) => state.auth.status);
  const authError = useSelector((state) => state.auth.error);
  const signupMethod = useSelector((state) => state.auth.signupMethod);

  const handleGoogleSignup = () => {
    const googleLoginURL = `${process.env.REACT_APP_API_URL}/api/auth/google/signup`;
    const newWindow = window.open(
      googleLoginURL,
      "_blank",
      "width=500,height=600"
    );

    const handleStorageChange = (event) => {
      if (event.key === "authSuccess" && event.newValue === "true") {
        dispatch(fetchUser()); // Dispatch the fetchUser action
        localStorage.removeItem("authSuccess"); // Clean up
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Optional: Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      agreement: false,
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(12, "Password should be at least 12 characters")
        .required("Required"),
      agreement: Yup.boolean().oneOf(
        [true],
        "You must agree to the Terms of Service and Privacy Policy to register."
      ),
    }),
    onSubmit: (values) => {
      dispatch(signupUser(values));
    },
  });
  useEffect(() => {
    let timer;
    if (authStatus === "loading") {
      if (signupMethod !== "google") {
        toast.loading("Registering user...");
      }
    } else if (authStatus === "succeeded") {
      toast.dismiss();
      if (signupMethod !== "google") {
        toast.success("Account created successfully. Activation link sent, please check your inbox.");
      } else {
        navigate("/dashboard");
      }
      timer = setTimeout(() => {
        dispatch(resetAuthStatus());
        formik.resetForm();
        // navigate("/login");
      }, 3000);
    } else if (authStatus === "failed") {
      toast.dismiss();
      toast.error(authError || "Error registering user.");
    }
    return () => {
      clearTimeout(timer);
    };
  }, [authStatus, authError, navigate, dispatch]);

  // Cleanup effect when the component unmounts
  useEffect(() => {
    return () => {
      dispatch(resetAuthStatus());
    };
  }, [dispatch]);

  return (
    <div className="container px-4 py-4 mx-auto auth-form">
      <div>
        <Toaster toastOptions={{
          duration: 7000,
          style: {
            maxWidth: '650px',
          },
        }} />
      </div>
      <div className="card card0 card-shadow">
        <div className="d-flex flex-lg-row flex-column-reverse">
          <div className="card card1">
            <form onSubmit={formik.handleSubmit}>
              <div className="row justify-content-center my-auto">
                <div className="col-md-8 col-10 my-2">
                  <div className="row justify-content-center px-3 mb-5">
                    <img id="logo" src={logo} />
                  </div>

                  <div className="form-group">
                    <label className="form-control-label text-muted">
                      First Name
                    </label>
                    <input
                      type="text"
                      id="first-name"
                      name="firstName"
                      placeholder="First Name"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div className="error">{formik.errors.firstName}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="form-control-label text-muted">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="last-name"
                      name="lastName"
                      placeholder="Last Name"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div className="error">{formik.errors.lastName}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="form-control-label text-muted">
                      Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="error">{formik.errors.email}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="form-control-label text-muted">
                      Password
                    </label>
                    <input
                      type="password"
                      id="psw"
                      name="password"
                      placeholder="Password"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />

                    {formik.touched.password && formik.errors.password ? (
                      <div className="error">{formik.errors.password}</div>
                    ) : null}
                  </div>

                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "0px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "3px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="agreement"
                        name="agreement"
                        style={{ width: "16px", marginRight: "8px" }} // Adjust margin for spacing between checkbox and label
                        checked={formik.values.agreement}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label
                        htmlFor="agreement"
                        className="form-control-label text-muted"
                        style={{ fontSize: "14px", marginLeft: "2px" }}
                      >
                        I agree to the{" "}
                        <a
                          href="https://belliodigital.ai/terms-of-service"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Service
                        </a>{" "}
                        and the{" "}
                        <a
                          href="https://belliodigital.ai/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                        .
                      </label>
                    </div>
                    {formik.touched.agreement && formik.errors.agreement && (
                      <div
                        className="error"
                        style={{
                          color: "red",
                          marginTop: "5px",
                          width: "100%",
                        }}
                      >
                        {formik.errors.agreement}
                      </div>
                    )}
                  </div>

                  <div className="row justify-content-center my-2 px-3">
                    <button className="btn-block btn-color">Register</button>
                  </div>
                  <div className="row justify-content-center my-4">
                    <button
                      type="button"
                      class="login-with-google-btn"
                      onClick={handleGoogleSignup}
                    >
                      <span class="button-content">
                        <span class="google-logo"></span>
                        Sign up with Google
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card card2">
            <div className="my-auto mx-md-5 px-md-5 right">
              <h3 className="text-white">We are more than just a company</h3>
              <small className="text-white">
                Your experience is something that we value above all else. Your
                time is valuable. Your problems are our problems. Your success
                is our success. If there is ever anything you need help with,
                please don’t hesitate to reach out to us!
              </small>
              <div className="privacy-terms-links">
                <a
                  href="https://belliodigital.ai/privacy-policy"
                  className="text-light"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                <span className="text-light"> | </span>
                <a
                  href="https://belliodigital.ai/terms-of-service"
                  className="text-light"
                  target="_blank"
                >
                  Terms of Service
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
