import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from '../auth/authSlice';
import { getAllWorkspaces, createWorkspaceAPI, updateWorkspaceAPI } from './workspaceAPI';

export const fetchWorkspaces = createAsyncThunk('workspace/fetchWorkspaces', async () => {
    const response = await getAllWorkspaces();
    return response.data;
});

export const addWorkspace = createAsyncThunk('workspace/addWorkspace', async (workspaceData) => {
    const response = await createWorkspaceAPI(workspaceData);
    return response.data;
});

export const updateWorkspace = createAsyncThunk('workspace/updateWorkspace', async (workspaceData) => {
    const response = await updateWorkspaceAPI(workspaceData);
    return response.data;
});

const initialState = {
    workspaces: [],
    loading: 'idle',
    addWorkspaceStatus: 'idle',  // New state
    updateWorkspaceStatus: 'idle',
    error: null,
};

const workspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        resetWorkspaceStatus: (state) => {
            state.addWorkspaceStatus = 'idle';  // Reset the addWorkspaceStatus
            state.updateWorkspaceStatus = 'idle';
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkspaces.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(fetchWorkspaces.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.workspaces = action.payload;
            })
            .addCase(fetchWorkspaces.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
            .addCase(addWorkspace.pending, (state) => {
                state.addWorkspaceStatus = 'loading';
            })
            .addCase(addWorkspace.fulfilled, (state, action) => {
                state.addWorkspaceStatus = 'succeeded';
                state.workspaces.push(action.payload.workspace);
            })
            .addCase(addWorkspace.rejected, (state, action) => {
                state.addWorkspaceStatus = 'failed';
                state.error = action.error.message || action.payload.message;
            })
            .addCase(updateWorkspace.pending, (state) => {
                state.updateWorkspaceStatus = 'loading';
            })
            .addCase(updateWorkspace.fulfilled, (state, action) => {
                state.updateWorkspaceStatus = 'succeeded';
                const index = state.workspaces.findIndex(workspace => workspace._id === action.payload._id);
                if (index !== -1) {
                    state.workspaces[index] = action.payload;
                }
            })
            .addCase(updateWorkspace.rejected, (state, action) => {
                state.updateWorkspaceStatus = 'failed';
                state.error = action.error.message || action.payload.message;
            })
            .addCase(logout.fulfilled, (state) => {
                // Reset the state to its initial value on successful logout
                return initialState;
            });
    },
});

export const { resetWorkspaceStatus } = workspaceSlice.actions;
export default workspaceSlice.reducer;