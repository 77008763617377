import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllPlans, resetPlanState } from '../../features/plan/planSlice';
import { upgradePlan, attachPaymentMethod, fetchSavedPaymentMethods } from '../../features/subscription/subscriptionSlice';
import { fetchUser } from '../../features/auth/authSlice';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Modal from './Modal';
import { useNavigate } from 'react-router-dom';

const UpgradePlan = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { plans, loading, error } = useSelector((state) => state.plan);
    const currentPlanDetails = useSelector((state) => state.userAccount.planDetails);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPlanId, setSelectedPlanId] = useState(null);
    const savedPaymentMethods = useSelector((state) => state.subscription.savedPaymentMethods);
    const { upgrading } = useSelector((state) => state.subscription);
    // State to manage showing the new payment form
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    // State to manage selected payment method
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);
    const stripe = useStripe();
    const elements = useElements();

    // Function to toggle between saved methods and payment form
    const togglePaymentForm = () => {
        setShowPaymentForm(!showPaymentForm);
    };

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    useEffect(() => {
        dispatch(resetPlanState());
        dispatch(fetchAllPlans());
        dispatch(fetchSavedPaymentMethods());
    }, [dispatch]);

    const handleUpgradeClick = (planId) => {
        setSelectedPlanId(planId);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedPlanId(null);
        setShowPaymentForm(false);
    };

    // Function to handle upgrade using a saved payment method
    const handleUpgradeWithSavedMethod = async () => {
        if (selectedPaymentMethodId) {
            dispatch(upgradePlan({
                planId: selectedPlanId,
                paymentMethodId: selectedPaymentMethodId
            })).then(handleUpgradeResponse);
        }
    };

    const handleUpgrade = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            console.log('Stripe has not loaded');
            return;
        }
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.error('[Error]', error);
        } else {
            dispatch(attachPaymentMethod({ paymentMethodId: paymentMethod.id }))
                .then(attachResponse => handleAttachResponse(attachResponse, selectedPlanId));
        }
    };

    const handleAttachResponse = (attachResponse, selectedPlanId) => {
        if (attachResponse.error) {
            console.error('Payment method attachment failed:', attachResponse.error);
        } else {
            dispatch(upgradePlan({
                planId: selectedPlanId
            })).then(handleUpgradeResponse);
        }
    };

    const handleUpgradeResponse = (upgradeResponse) => {
        if (upgradeResponse.error) {
            console.error('Upgrade failed:', upgradeResponse.error);
        } else {
            dispatch(fetchUser());
            navigate('/billing-and-subscription');
        }
    };

    const renderSavedPaymentMethods = () => {
        // Function to return the image URL based on the card brand
        const getCardBrandImage = (brand) => {
            switch (brand) {
                case 'visa': return 'https://static-00.iconduck.com/assets.00/visa-icon-2048x1313-o6hi8q5l.png';
                case 'mastercard': return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwqeK4YwRLe6_Jo3S708Zc7OR8kgSsdPQiVQ&usqp=CAU';
                default: return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJyk1sSKF2qBHH-4BLJXKtny1zND5WePsDRw&usqp=CAU';
            }
        };
        return (
            <div className='mt-3'>
                {savedPaymentMethods.map((method) => (
                    <div key={method.id} className="form-check mt-2">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="savedPaymentMethod"
                            id={`paymentMethod-${method.id}`}
                            value={method.id}
                            onChange={() => setSelectedPaymentMethodId(method.id)}
                            checked={selectedPaymentMethodId === method.id}
                        />
                        <label className="form-check-label d-flex align-items-center" htmlFor={`paymentMethod-${method.id}`}>
                            <img
                                src={getCardBrandImage(method.card.brand)}
                                alt={`${method.card.brand} card`}
                                style={{ width: '30px', marginRight: '10px' }}
                            />
                            Card ending in {method.card.last4}
                        </label>
                    </div>
                ))}
                <button onClick={() => setShowPaymentForm(true)} className="btn btn-link">
                    Add New Payment Method
                </button>
            </div>
        );
    };

    return (
        <div className="container mt-4 mb-5">
            <div className="settings-header mb-4">
                <h2>Upgrade Plan</h2>
            </div>
            <div className='row'>
                {loading === 'loading' && <div>Loading plans...</div>}
                {error && <div>Error fetching plans: {error}</div>}
                {plans && plans.filter(plan => plan.price > currentPlanDetails.price).length > 0 ? (
                    plans.filter(plan => plan.price > currentPlanDetails.price).map((plan) => (
                        <div key={plan._id} className='col-md-4 mt-2'>
                            <div className="section mb-4 campaign-card">
                                <div className="card-header" style={{ backgroundImage: "linear-gradient(130deg, #0700DD 0%, #00f2ff 100%)" }}>
                                    <h5 className="card-title text-white text-uppercase text-center">{plan.name}</h5>
                                    <h6 className="h1 text-white text-center">${plan.price}</h6>
                                </div>
                                <div className="card-body">
                                    <ul className="list-unstyled mb-4" style={{ textAlign: 'left' }}>
                                        {plan.features.map((feature, index) => (
                                            <li key={index} className="mb-3">
                                                <span className="mr-3">
                                                    <i className="fa fa-check" style={{ color: "#007bff" }}></i>
                                                </span>
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                    <button onClick={() => handleUpgradeClick(plan._id)} className="btn btn-primary card-button mt-3">
                                        Upgrade to {plan.name}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))) : loading !== 'loading' && (
                        <div className='col-12 text-center'>No plans available for upgrade.</div>
                    )}
            </div>

            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <div className="popup-overlay">
                    <div className="popup-content">
                        <div className="container">
                            <h3>Upgrade to {plans.find(plan => plan._id === selectedPlanId)?.name}</h3>

                            {!showPaymentForm && savedPaymentMethods.length > 0 ? (
                                <div>
                                    {renderSavedPaymentMethods()}
                                </div>
                            ) : (
                                <form onSubmit={handleUpgrade} className='mt-3'>
                                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                                    <div className="popup-buttons">
                                        {/* <button onClick={() => setShowPaymentForm(false)} className="btn btn-link">
                                            Use Saved Payment Method
                                        </button> */}
                                        <button className="btn btn-secondary mr-2 popup-button" onClick={closeModal}>Cancel</button>
                                        <button className="btn btn-primary popup-button" type="submit" disabled={!stripe || upgrading}>
                                            {upgrading ? 'Upgrading...' : 'Confirm Upgrade'}
                                        </button>
                                    </div>
                                </form>
                            )}

                            <div className="popup-buttons">
                                {!showPaymentForm && savedPaymentMethods.length > 0 && (
                                    <>
                                        <button className="btn btn-secondary mr-2 popup-button" onClick={closeModal}>Cancel</button>
                                        <button className="btn btn-primary popup-button" onClick={handleUpgradeWithSavedMethod} disabled={upgrading}>
                                            {upgrading ? 'Upgrading...' : 'Confirm Upgrade'}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </div>
    );
};

export default UpgradePlan;