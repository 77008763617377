import React, { useEffect, useState, useMemo } from 'react';
import CircleLoader from "react-spinners/CircleLoader";
import { useSelector, useDispatch } from 'react-redux';
import { fetchUser } from '../../features/auth/authSlice';
import { processWebhookDataAsync } from '../../features/form/formSlice';
import './steps.css';

const Step5 = ({ nextClick, prevClick }) => {
    const dispatch = useDispatch();
    const userScopes = useSelector(state => state.auth.user.user.scopes);
    const webhookUniqueId = useSelector(state => state.form.webhookUniqueId);
    const webhookData = useSelector(state => state.form.webhookData);
    const webhookStatus = useSelector(state => state.form.webhookStatus);
    const webhookError = useSelector(state => state.form.webhookError);

    const [isLoading, setIsLoading] = useState(false);
    const [hasDispatched, setHasDispatched] = useState(false); // New state to track dispatch

    const hasRequiredPermissions = useMemo(() => {
        const requiredScopes = ['Spreadsheets', 'Drive', 'GmailCompose'];
        return requiredScopes.every(scope => userScopes.includes(scope));
    }, [userScopes]);

    useEffect(() => {
        if (hasRequiredPermissions && !hasDispatched) {
            setIsLoading(true);
            dispatch(processWebhookDataAsync({ uniqueId: webhookUniqueId, webhookData: webhookData }));
            setHasDispatched(true); // Set to true after dispatching
        }
    }, [hasRequiredPermissions, dispatch, webhookUniqueId, hasDispatched]);

    useEffect(() => {
        if (webhookStatus === 'succeeded') {
            setIsLoading(false);
            nextClick();
        } else if (webhookStatus === 'failed') {
            setIsLoading(false);
        }
    }, [webhookStatus, nextClick]);

    const handleGrantPermissions = () => {
        const googlePermissionsURL = `${process.env.REACT_APP_API_URL}/api/auth/google/sheets-and-drive`;
        const newWindow = window.open(googlePermissionsURL, "_blank", "width=500,height=600");

        const handleStorageChange = (event) => {
            if (event.key === 'authSuccess' && event.newValue === 'true') {
                dispatch(fetchUser()); // Fetch the updated user data with new scopes
                localStorage.removeItem('authSuccess'); // Clean up
            }
        };

        window.addEventListener('storage', handleStorageChange);

        // Optional: Remove the event listener when the component is unmounted
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    };

    return (
        <>
            <div className="multisteps-form__panel pl-2 pr-2 rounded bg-white js-active" data-animation="scaleIn">
                <div className="multisteps-form__content">
                    <h3 className='mb-3 mt-3'>Setting Up Your Form</h3>
                    {
                        isLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircleLoader color="#FF66AB" loading={true} size={50} />
                        </div>
                        ) : webhookStatus === 'failed' ? (
                            <p>Something is wrong. Please try again.</p>
                        ) : (
                            <>
                                <p>You don't have the required permissions. Please grant access:</p>
                                <button className="btn btn-primary blue-aqua-gradient" onClick={handleGrantPermissions}>Grant Google Permissions</button>
                            </>
                        )
                    }
                </div>
                <div className="button-row d-flex mt-3 pb-3">
                    <div className="ml-auto">
                        <button className="btn btn-secondary mr-2 popup-button" onClick={prevClick}>
                            Previous
                        </button>
                        <button className="btn btn-primary popup-button" onClick={nextClick} disabled={!hasRequiredPermissions || isLoading}>
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step5;
