import api from '../../helpers/api'; 

export const createWorkspaceAPI = (workspaceData) => {
    return api.post('/api/workspace', workspaceData);
};

export const updateWorkspaceAPI = (workspaceData) => {
    const id = workspaceData.get('id');
    return api.put(`/api/workspace/${id}`, workspaceData);
};

export const getAllWorkspaces = () => {
    return api.get('/api/workspace/all');
};