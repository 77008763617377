import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import Login from "./pages/Login";
import Register from "./pages/Register";
import AccountActivation from "./pages/AccountActivation"
import ForgotPass from "./pages/ForgotPass";
import ResetPass from "./pages/ResetPass";
import ProfileSettings from "./pages/ProfileSettings";
import BillingAndSubsSettings from "./pages/BillingAndSubsSettings";
import HelpAandSupport from "./pages/HelpAndSupport";
import Dashboard from "./pages/Dashboard";
import WorkspaceSettings from "./pages/WorkspaceSettings";
import APIManagement from "./pages/APIManagement";
import Forms from "./pages/Forms";
import Layout from "./components/Layout/Layout";
import { LoginSuccess } from "./components/LoginSuccess/LoginSuccess";
import { Success } from "./components/LoginSuccess/Success";
import UpgradePlan from "./pages/UpgradePlan";
import PaymentHistory from "./pages/PaymentHistory";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import {
  fetchAllowedDomainsAsync,
  fetchToken,
  setLoading,
} from "./features/auth/authSlice";

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const loading = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchToken()).then((result) => {
      localStorage.setItem("csrfToken", result.payload.token);
    });
  }, []);
  
  React.useEffect(() => {
    dispatch(fetchAllowedDomainsAsync());
  }, [dispatch]);

  React.useEffect(() => {
    if (!isAuthenticated) {
      <Navigate to="/login" />;
    }
  }, [isAuthenticated]);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/account-activation",
      element: <AccountActivation></AccountActivation>,
    },
    {
      path: "/privacy-policy",
      element: (
        <Layout>
          <PrivacyPolicy />
        </Layout>
      ),
    },
    {
      path: "/terms-of-service",
      element: (
        <Layout>
          <TermsOfService />
        </Layout>
      ),
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPass />,
    },
    {
      path: "/reset-password",
      element: <ResetPass />,
    },
    {
      path: "/dashboard",
      element: isAuthenticated ? (
        <Layout>
          <Dashboard />
        </Layout>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/workspace-settings/:id",
      element: isAuthenticated ? (
        <Layout>
          <WorkspaceSettings />
        </Layout>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/integrated-forms/:workspaceId",
      element: isAuthenticated ? (
        <Layout>
          <Forms />
        </Layout>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/profile",
      element: isAuthenticated ? (
        <Layout>
          <ProfileSettings />
        </Layout>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/api-management",
      element: isAuthenticated ? (
        <Layout>
          <APIManagement />
        </Layout>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/billing-and-subscription",
      element: isAuthenticated ? (
        <Layout>
          <BillingAndSubsSettings />
        </Layout>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/payment-history",
      element: isAuthenticated ? (
        <Layout>
          <PaymentHistory />
        </Layout>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/upgrade",
      element: isAuthenticated ? (
        <Layout>
          <UpgradePlan />
        </Layout>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/help-and-support",
      element: isAuthenticated ? (
        <Layout>
          <HelpAandSupport />
        </Layout>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/login/success",
      element: <LoginSuccess />,
    },
    {
      path: "/login/success-message",
      element: <Success></Success>,
    },
  ]);

  if (loading) return <></>;

  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
