import { createSlice, createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createOrUpdateApiKeyAPI,
  fetchUserAccountDataAPI,
} from "./userAccountAPI";

// Action to set the user's plan details
export const setUserPlanDetails = createAction(
  "userAccount/setUserPlanDetails"
);

export const createOrUpdateApiKey = createAsyncThunk(
  "userAccount/createOrUpdateApiKey",
  async (userData) => {
    const response = await createOrUpdateApiKeyAPI(userData);
    return response.data;
  }
);

export const fetchUserAccountData = createAsyncThunk(
  "userAccount/fetchUserAccountData",
  async (userId) => {
    const response = await fetchUserAccountDataAPI(userId);
    return response.data;
  }
);

const initialState = {
  openApiKey: "",
  apiKeyStatus: true,
  loading: "idle",
  fetchLoading: "idle",
  error: null,
  planDetails: null,
};

const userAccountSlice = createSlice({
  name: "userAccount",
  initialState,
  reducers: {
    resetUserAccountStatus: (state) => {
      state.openApiKey = "";
      state.loading = "idle";
      state.fetchLoading = "idle";
      state.error = null;
      state.planDetails = null;
    },
    setUserPlanDetails: (state, action) => {
      state.planDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrUpdateApiKey.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(createOrUpdateApiKey.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.openApiKey = action.payload.userAccount.openApiKey;
        state.apiKeyStatus = action.payload.userAccount.apiKeyStatus;
      })
      .addCase(createOrUpdateApiKey.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message || action.payload.message;
      })
      // For fetchUserAccountData
      .addCase(fetchUserAccountData.pending, (state) => {
        state.fetchLoading = "loading";
      })
      .addCase(fetchUserAccountData.fulfilled, (state, action) => {
        state.fetchLoading = "succeeded";
        state.openApiKey = action.payload.openApiKey;
        state.apiKeyStatus = action.payload.apiKeyStatus;
      })
      .addCase(fetchUserAccountData.rejected, (state, action) => {
        state.fetchLoading = "failed";
        state.error = action.error.message || action.payload.message;
      })
      .addCase(setUserPlanDetails, (state, action) => {
        state.planDetails = action.payload;
      });
  },
});

export const { resetUserAccountStatus } = userAccountSlice.actions;
export default userAccountSlice.reducer;
