import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import authReducer from "../features/auth/authSlice";
import userAccountReducer from "../features/userAccount/userAccountSlice";
import workspaceReducer from "../features/workspace/workspaceSlice";
import productServiceReducer from "../features/productService/productServiceSlice";
import formReducer from "../features/form/formSlice";
import emailConfigReducer from "../features/emailConfig/emailConfigSlice";
import aiReducer from "../features/ai/aiSlice";
import planReducer from "../features/plan/planSlice";
import subscriptionReducer from "../features/subscription/subscriptionSlice";

const persistConfig = {
  key: "root",
  storage,
  // whitelist: ['auth'], // if you want to persist only the auth state
};

const rootReducer = combineReducers({
  auth: authReducer,
  userAccount: userAccountReducer,
  workspace: workspaceReducer,
  productService: productServiceReducer,
  form: formReducer,
  emailConfig: emailConfigReducer,
  ai: aiReducer,
  plan: planReducer,
  subscription: subscriptionReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [...getDefaultMiddleware()],
});

export const persistor = persistStore(store);
// Dispatch action to fetch CSRF token during application initialization
