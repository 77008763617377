import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSavedPaymentMethods,
  removePaymentMethod,
  fetchPaymentHistory,
  resetFetchPaymentMethodsError,
  resetFetchPaymentHistoryError,
} from "../../features/subscription/subscriptionSlice";
import { Link } from "react-router-dom";
// import mastercard from '../../assets/images/default-images/MasterCard_Logo.svg_.webp';
import APPopup from "../AddPayment/AddPayment";

const BillingAndSubs = () => {
  const dispatch = useDispatch();

  const [showPopup, setShowPopup] = useState(false);
  const planDetails = useSelector((state) => state.userAccount.planDetails);

  const {
    savedPaymentMethods,
    paymentHistory,
    fetchingPaymentHistory,
    fetchPaymentHistoryError,
    fetchingPaymentMethods,
  } = useSelector((state) => state.subscription);

  useEffect(() => {
    dispatch(resetFetchPaymentMethodsError());
    dispatch(resetFetchPaymentHistoryError());
    dispatch(fetchSavedPaymentMethods());
    dispatch(fetchPaymentHistory());
  }, [dispatch]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleDelete = (paymentMethodId) => {
    if (
      window.confirm("Are you sure you want to delete this Payment Method?")
    ) {
      dispatch(removePaymentMethod(paymentMethodId));
    }
  };

  const displayPlanName =
    planDetails?.name === "Free Trial"
      ? `${planDetails?.name} for a month`
      : planDetails?.name;

  const getCardBrandImage = (brand) => {
    const brandImages = {
      visa: "https://static-00.iconduck.com/assets.00/visa-icon-2048x1313-o6hi8q5l.png",
      mastercard:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwqeK4YwRLe6_Jo3S708Zc7OR8kgSsdPQiVQ&usqp=CAU",
      default:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJyk1sSKF2qBHH-4BLJXKtny1zND5WePsDRw&usqp=CAU",
    };
    return brandImages[brand] || brandImages["default"];
  };

  return (
    <>
      <div className="container mt-4 mb-5">
        <div className="settings-header mb-4">
          <h2>Billing & Subscription</h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            {/* Billing & Subscription */}
            <div class="section mb-4 campaign-card">
              <div
                class="card-header"
                style={{
                  backgroundImage:
                    "linear-gradient(130deg, #0700DD 0%, #00f2ff 100%)",
                }}
              >
                <h5 class="card-title text-white text-uppercase text-center">
                  {displayPlanName}
                </h5>
                <h6 class="h1 text-white text-center">
                  ${planDetails?.price}
                  <span class="h6 text-white-50"></span>
                </h6>
              </div>
              <div class="card-body">
                <ul class="list-unstyled mb-4" style={{ textAlign: "left" }}>
                  {planDetails?.features.map((feature, index) => (
                    <li key={index} className="mb-3">
                      <span className="mr-3">
                        <i
                          className="fa fa-check"
                          style={{ color: "#007bff" }}
                        ></i>
                      </span>
                      {feature}
                    </li>
                  ))}
                </ul>
                <Link
                  to={`/upgrade`}
                  className="btn btn-primary card-button mt-3"
                >
                  Upgrade your plan
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4" style={{ height: "100%" }}>
            {/* Billing & Subscription */}
            <div className="section mb-4 campaign-card">
              <div className="mb-3">
                <h4>Payment Methods</h4>
                {/* Check if there are any saved payment methods */}
                {fetchingPaymentMethods ? (
                  <div className="text-center">Loading payment methods...</div>
                ) : savedPaymentMethods.length === 0 ? (
                  // Display message if no payment methods are available
                  <div className="col-12 text-center mt-4">
                    <p>No payment methods found.</p>
                  </div>
                ) : (
                  // Map through saved payment methods and display them
                  savedPaymentMethods.map((method) => (
                    <div
                      key={method.id}
                      className="form-group"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "35px",
                      }}
                    >
                      <img
                        src={getCardBrandImage(method.card.brand)}
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                          marginRight: "10px",
                          width: "40px",
                        }}
                      />
                      <input
                        type="text"
                        className="form-control"
                        style={{ display: "inline-block" }}
                        placeholder={`**** **** **** ${method.card.last4}`}
                        readOnly
                      />
                      <button
                        onClick={() => handleDelete(method.id)}
                        className="btn btn-primary card-button ml-1"
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="section mb-4 campaign-card">
              <div className="mb-3">
                <h4>Payment History</h4>
                <ul
                  className="list-unstyled mb-4 mt-4"
                  style={{ textAlign: "left" }}
                >
                  {fetchingPaymentHistory ? (
                    <div className="text-center">
                      Loading payment history...
                    </div>
                  ) : paymentHistory.length > 0 ? (
                    paymentHistory.map((invoice, index) => (
                      <li key={index} className="mb-3">
                        <span className="mr-3">
                          <i
                            className="fa fa-calendar"
                            style={{ color: "#007bff" }}
                          ></i>
                        </span>
                        Paid on{" "}
                        {new Date(invoice.created * 1000).toLocaleDateString()}{" "}
                        | Amount ${invoice.amount_paid / 100}
                        {invoice.invoice_pdf && (
                          <a
                            href={invoice.invoice_pdf}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-sm card-button ml-2"
                            style={{
                              paddingTop: "0px",
                              paddingBottom: "0px",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                            }}
                          >
                            <i
                              class="fa fa-download"
                              style={{ fontSize: "11px" }}
                            ></i>
                          </a>
                        )}
                      </li>
                    ))
                  ) : (
                    // Display message if no payment methods are available
                    <div className="col-12 text-center">
                      <p>No payment history available.</p>
                    </div>
                  )}
                </ul>
                {/* <Link to={`/payment-history`} className="btn btn-primary card-button mb-2">View More</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopup && <APPopup onClose={() => togglePopup(false)} />}
    </>
  );
};

export default BillingAndSubs;
