import api from '../../helpers/api';

export const upgradePlanAPI = (upgradeData) => {
    return api.post('/api/subscription/upgrade', upgradeData);
};

export const attachPaymentMethodAPI = ({ paymentMethodId }) => {
    return api.post('/api/subscription/attach-payment-method', { paymentMethodId });
};

export const fetchUserSavedPaymentMethodsAPI = (userId) => {
    return api.get('/api/subscription/payment-methods');
};

export const removePaymentMethodAPI = (paymentMethodId) => {
    return api.delete(`/api/subscription/remove-payment-method/${paymentMethodId}`);
};

export const fetchPaymentHistoryAPI = () => {
    return api.get('/api/subscription/payment-history');
};