import api from '../../helpers/api'; 

export const createProductServiceAPI = (productServiceData) => {
    return api.post('/api/product-service', productServiceData);
};

export const fetchProductsAPI = (workspaceId) => {
    return api.get(`/api/product-service/all?workspaceId=${workspaceId}`);
};

export const updateProductServiceAPI = ({ productServiceId, updates }) => {
    return api.put(`/api/product-service/${productServiceId}`, updates);
};

export const deleteProductServiceAPI = async (productServiceId) => {
    return api.delete(`/api/product-service/${productServiceId}`);
}