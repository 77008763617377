import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPartialForm } from "../../features/form/formSlice";
import IntegrateFormPopup from "../IntegrateFormPopup/IntegrateFormPopup";
import PSPopup from "../PSPopup/PSPopup";
import "./integratedforms.css";
import ProductsServicesList from "../List/ProductsServicesList";
import FormsList from "../List/FormsList";

const IntegratedForms = () => {
  const dispatch = useDispatch();
  const { workspaceId } = useParams();

  const [showPopup, setShowPopup] = useState(false);
  const [showPSPopup, setShowPSPopup] = useState(false);
  const [activeTab, setActiveTab] = useState("Forms");

  const toggleFIPopup = () => {
    dispatch(resetPartialForm());
    setShowPopup(!showPopup);
  };

  const togglePSPopup = () => {
    setShowPSPopup(!showPSPopup);
  };

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const handleEditForm = () => {
    setShowPopup(!showPopup);
  };

  return (
    <>
      <div class="container mt-4">
        <div class="workspace-header mb-4">
          <div class="workspace-icons">
            <Link to="/dashboard">
              <i class="fa fa-arrow-left fa-2x mr-2"></i>
            </Link>
            <div className="tabs">
              <button
                className={`${activeTab === "Forms" ? "active" : "inactive"}`}
                onClick={() => toggleTab("Forms")}
              >
                Forms
              </button>
              <button
                className={`ml-1 ${
                  activeTab === "Services" ? "active" : "inactive"
                }`}
                onClick={() => toggleTab("Services")}
              >
                Services/Products
              </button>
            </div>
          </div>
          <div class="plus-icon dropdown">
            <i
              className="fa fa-plus-circle"
              onClick={activeTab === "Services" ? togglePSPopup : toggleFIPopup}
            ></i>
          </div>
        </div>
        {activeTab === "Forms" && <FormsList onEditForm={handleEditForm} />}
        {activeTab === "Services" && <ProductsServicesList />}
      </div>
      {showPopup && <IntegrateFormPopup onClose={toggleFIPopup} />}
      {showPSPopup && (
        <PSPopup onClose={togglePSPopup} workspaceId={workspaceId} />
      )}
    </>
  );
};

export default IntegratedForms;
