import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchWorkspaces } from "../../features/workspace/workspaceSlice";
import { Link } from "react-router-dom";
import WSPopup from "../WSPopup/WSPopup";
import "./workspaces.css";
import defaultWorkspaceImage from "../../assets/images/default-images/default-workspace-image.png";

const WorkSpaces = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);

  const workspaces = useSelector((state) => state.workspace.workspaces);

  const togglePopup = (isEditMode, workspace = null) => {
    setShowPopup(!showPopup);
    setEditMode(isEditMode);
    setSelectedWorkspace(workspace);
  };

  return (
    <>
      <div class="container mt-4">
        <div class="workspace-header mb-4">
          <div class="workspace-icons">
            <i class="fa fa-folder fa-2x mr-2"></i>
            <div class="workspace-title">Workspaces</div>
          </div>
          <div class="plus-icon dropdown">
            <i class="fa fa-plus-circle" onClick={() => togglePopup(false)}></i>
          </div>
        </div>
        <div class="row">
          {workspaces.length === 0 ? (
            <div className="col-12 text-center mt-4">
              <h4>No WorkSpaces available.</h4>
              <p>Click on the "+" icon to create a new workspace.</p>
            </div>
          ) : (
            workspaces.map((workspace, index) => (
              <div class="col-md-4 mb-4" key={index}>
                <div class="campaign-card">
                  <div class="settings-icon">
                    <Link to={`/workspace-settings/${workspace._id}`}>
                      <i class="fa fa-cog"></i>
                    </Link>
                  </div>
                  <div>
                    <div class="card-title">{workspace.businessName}</div>
                    <img
                      src={
                        workspace.businessLogo
                          ? workspace.businessLogo
                          : defaultWorkspaceImage
                      }
                      alt={workspace.businessName}
                      class="card-image"
                    />
                  </div>
                  <button
                    class="card-button"
                    onClick={() => togglePopup(true, workspace)}
                  >
                    Edit
                  </button>
                  <Link
                    to={`/integrated-forms/${workspace._id}`}
                    class="card-button"
                  >
                    View
                  </Link>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      {/* {showPopup && <WSPopup onClose={() => togglePopup(false)} editMode={editMode} />} */}
      {showPopup && (
        <WSPopup
          onClose={() => togglePopup(false)}
          editMode={editMode}
          workspace={selectedWorkspace}
        />
      )}
    </>
  );
};

export default WorkSpaces;
