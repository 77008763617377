import React, { useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createOrUpdateApiKey, fetchUserAccountData, resetUserAccountStatus } from '../../features/userAccount/userAccountSlice';

const APIManagementSettings = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user.user);
  const userAccount = useSelector(state => state.userAccount);
  const apiKeyStatus = useSelector(state => state.userAccount.apiKeyStatus);
  const loading = useSelector(state => state.userAccount.loading);
  const fetchLoading = useSelector(state => state.userAccount.fetchLoading);
  const [showCheckbox, setShowCheckbox] = useState(!userAccount?.openApiKey);

  const handleDisableClick = () => {
    const formData = new FormData();
    formData.append('id', user._id);
    formData.append('openApiKey', userAccount.openApiKey);
    formData.append('apiKeyStatus', 'false');

    dispatch(createOrUpdateApiKey(formData));
  };

  const handleEnableClick = () => {
    const formData = new FormData();
    formData.append('id', user._id);
    formData.append('openApiKey', userAccount.openApiKey);
    formData.append('apiKeyStatus', 'true');

    dispatch(createOrUpdateApiKey(formData));
  };

  const formik = useFormik({
    initialValues: {
      openApiKey: userAccount?.openApiKey || '',
      agreement: false
    },
    validationSchema: Yup.object({
      openApiKey: Yup.string()
        .required('API Key is required'),
      agreement: Yup.boolean()
        .oneOf([true], 'You must agree to the use of AI models for automated data analysis and immediate processing.')
    }),
    onSubmit: values => {
      const userData = {
        id: user._id,
        openApiKey: values.openApiKey,
        agreement: values.agreement
      };

      dispatch(createOrUpdateApiKey(userData)).then(action => {
        if (createOrUpdateApiKey.fulfilled.match(action)) {
          toast.success('API Key updated successfully!');
        } else {
          toast.error('Error updating API Key.');
        }
        dispatch(resetUserAccountStatus());
      });
    },
  });

  useEffect(() => {
    // Dispatch the action when the component mounts
    dispatch(fetchUserAccountData(user._id));
  }, [dispatch, user._id]);

  useEffect(() => {
    // Only update the form field if the data has been successfully fetched
    if (userAccount.fetchLoading === 'succeeded' && userAccount.openApiKey !== formik.values.openApiKey) {
      formik.setFieldValue('openApiKey', userAccount.openApiKey);
    }
  }, [userAccount.fetchLoading, userAccount.openApiKey, formik
    .setFieldValue]);

  return (
    <>
      <Toaster />
      <div className="container mt-4">
        <div className="settings-header mb-4">
          <h2>API Management</h2>
        </div>
        {fetchLoading === 'loading' ? (
          // Display loading message or spinner when fetchLoading is 'loading'
          <div>Loading...</div>
        ) : (
          <>
            <div className="row">
              {/* Open API Key Section */}
              <div className="col-md-6">
                <div className="section mb-4 campaign-card">
                  <h2>Open API Key</h2>
                  <input
                    type="text"
                    className="form-control mb-3"
                    style={{ borderRadius: '0' }}
                    placeholder="Paste your API key here"
                    value={formik.values.openApiKey}
                    onChange={formik.handleChange}
                    name="openApiKey"
                    disabled={!apiKeyStatus}
                  />
                  {formik.errors.openApiKey && formik.touched.openApiKey ? (
                    <div className="text-danger">{formik.errors.openApiKey}</div>
                  ) : null}

                  {!userAccount.openApiKey && (
                    <div className="form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                        <input
                          type="checkbox"
                          checked={formik.values.agreement}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="agreement"
                          id="agreementCheckbox"
                          style={{ width: "20px", padding: "3px", marginTop: "-23px" }}
                        />
                        <label className="form-check-label" htmlFor="agreementCheckbox">
                          I agree to the use of <a href="https://belliodigital.ai/privacy-policy/#:~:text=3.4.%20Automated%20AI%20Analysis%20for%20Immediate%20Data%20Processing%3A" target="_blank" style={{ textDecoration: "underline" }} rel="noopener noreferrer">AI models for automated data analysis and immediate processing.</a>
                        </label>
                      </div>
                      {formik.errors.agreement && formik.touched.agreement && (
                        <div className="text-danger">{formik.errors.agreement}</div>
                      )}
                    </div>
                  )}

                  <div>
                    {userAccount?.apiKeyStatus && (
                      <button
                        className="btn btn-primary card-button mr-2"
                        type="button"
                        onClick={formik.handleSubmit}
                        disabled={loading === 'loading'}
                      >
                        {userAccount?.openApiKey ? 'Update' : 'Submit'}
                      </button>
                    )}
                    {userAccount?.openApiKey && (
                      userAccount?.apiKeyStatus ? (
                        <button className="btn btn-secondary card-button" type="button" onClick={handleDisableClick}>
                          Disable
                        </button>
                      ) : (
                        <button className="btn btn-primary card-button" type="button" style={{ backgroundImage: userAccount?.apiKeyStatus ? 'none' : 'linear-gradient(130deg, #2358D4 0%, #00f2ff 100%)' }} onClick={handleEnableClick} >
                          Enable
                        </button>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default APIManagementSettings;