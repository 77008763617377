import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateWorkspace, resetWorkspaceStatus } from '../../features/workspace/workspaceSlice';
import { fetchEmailConfigs } from '../../features/emailConfig/emailConfigSlice';
import IMAP from '../IMAP/IMAP';
import './workspacesettings.css';

const WorkspaceSettings = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const updateWorkspaceStatus = useSelector(state => state.workspace.updateWorkspaceStatus);
    const emailConfigStatus = useSelector(state => state.emailConfig.emailConfigStatus);
    const emailConfigs = useSelector(state => state.emailConfig.emailConfigs);
    const workspace = useSelector(state => state.workspace.workspaces.find(ws => ws._id === id));
    //IMAP
    const [showPopup, setShowPopup] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(!workspace?.apiKey);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };
    //Google
    const handleGoogleAuth = () => {
        const googleAuthURL = `${process.env.REACT_APP_API_URL}/api/auth/google/workspace-email/?workspaceId=${id}`;
        const newWindow = window.open(googleAuthURL, "_blank", "width=500,height=600");

        const handleStorageChange = (event) => {
            if (event.key === 'authSuccess2' && event.newValue === 'true') {
                dispatch(fetchEmailConfigs(id))
                localStorage.removeItem('authSuccess2'); // Clean up
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    };
    //API Key
    const handleDisableClick = () => {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('apiKeyStatus', 'false');

        dispatch(updateWorkspace(formData));
    };

    const handleEnableClick = () => {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('apiKeyStatus', 'true');

        dispatch(updateWorkspace(formData));
    };

    const formik = useFormik({
        initialValues: {
            apiKey: workspace?.apiKey || '',
            agreement: false
        },
        validationSchema: Yup.object({
            apiKey: Yup.string()
                .required('API Key is required'),
            agreement: Yup.boolean()
                .oneOf([true], 'You must agree to the use of AI models for automated data analysis and immediate processing.')
        }),
        onSubmit: values => {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('apiKey', values.apiKey);
            formData.append('agreement', values.agreement);

            dispatch(updateWorkspace(formData)).then(action => {
                if (updateWorkspace.fulfilled.match(action)) {
                    toast.success('API Key updated successfully!');
                } else {
                    toast.error('Error updating API Key.');
                }
                dispatch(resetWorkspaceStatus());
            });
        },
    });

    useEffect(() => {
        dispatch(fetchEmailConfigs(id));
    }, [dispatch, id]);

    return (
        <>
            <Toaster />
            <div className="container mt-4">
                <div className="workspace-header mb-4">
                    <div className="workspace-icons">
                        <Link to="/dashboard"><i className="fa fa-arrow-left fa-2x mr-2"></i></Link>
                        <div className="workspace-title">Settings</div>
                    </div>
                </div>
                {emailConfigStatus === 'loading' ? (
                    <div className="loading-indicator">
                        Loading...
                    </div>
                ) : (
                    <div className="row">
                        {/* Open API Key Section */}
                        <div className="col-md-6 mb-4">
                            <div className="section mb-4 campaign-card">
                                <h2>Open API Key</h2>
                                <input
                                    type="text"
                                    className="form-control mb-3"
                                    style={{ borderRadius: '0' }}
                                    placeholder="Paste your API key here"
                                    value={formik.values.apiKey}
                                    onChange={formik.handleChange}
                                    name="apiKey"
                                    disabled={!workspace?.apiKeyStatus} // Disable the input if apiKeyStatus is false
                                />
                                {formik.errors.apiKey && formik.touched.apiKey ? (
                                    <div className="text-danger">{formik.errors.apiKey}</div>
                                ) : null}

                                {showCheckbox && (
                                    <div className="form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                            <input
                                                type="checkbox"
                                                checked={formik.values.agreement}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                name="agreement"
                                                id="agreementCheckbox"
                                                style={{ width: "20px", padding: "3px", marginTop: "-23px" }}
                                            />
                                            <label className="form-check-label" htmlFor="agreementCheckbox">
                                                I agree to the use of <a href="https://belliodigital.ai/privacy-policy/#:~:text=3.4.%20Automated%20AI%20Analysis%20for%20Immediate%20Data%20Processing%3A" target="_blank" style={{ textDecoration: "underline" }} rel="noopener noreferrer">AI models for automated data analysis and immediate processing.</a>
                                            </label>
                                        </div>
                                        {formik.errors.agreement && formik.touched.agreement && (
                                            <div className="text-danger">{formik.errors.agreement}</div>
                                        )}
                                    </div>
                                )}

                                <div>
                                    {workspace?.apiKeyStatus && (
                                        <button
                                            className="btn btn-primary card-button mr-2"
                                            type="button"
                                            onClick={formik.handleSubmit}
                                            disabled={updateWorkspaceStatus === 'loading'}
                                        >
                                            {workspace?.apiKey ? 'Update' : 'Submit'}
                                        </button>
                                    )}
                                    {workspace?.apiKey && (
                                        workspace?.apiKeyStatus ? (
                                            <button className="btn btn-secondary card-button" type="button" onClick={handleDisableClick}>
                                                Disable
                                            </button>
                                        ) : (
                                            <button className="btn btn-primary card-button" type="button" style={{ backgroundImage: workspace?.apiKeyStatus ? 'none' : 'linear-gradient(130deg, #2358D4 0%, #00f2ff 100%)' }} onClick={handleEnableClick} >
                                                Enable
                                            </button>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* Email Triggering Section */}
                        <div className="col-md-6">
                            <div className="section mb-4 campaign-card">
                                <h2>Email Triggering</h2>
                                <p>Select the account you'd like to use for receiving draft emails related to this workspace:</p>
                                {/* For Google */}
                                {emailConfigs.workspaceEmail && (
                                    <>
                                        <div className="email-config-display">
                                            <i className="fa fa-check-circle text-success mr-2"></i>
                                            {emailConfigs.workspaceEmail}
                                        </div>
                                        <p>You can change the active email by clicking on one of them.</p>
                                    </>
                                )}
                                <div className="btn-group">
                                    <button className="btn card-button mr-2" onClick={handleGoogleAuth}>
                                        <i className="fa fa-google mr-2"></i> Google
                                    </button>

                                    {/* <button className="btn card-button mr-2">
                                        <i className="fa fa-windows mr-2"></i> Microsoft
                                    </button> */}

                                    <button className="btn card-button" onClick={togglePopup}>
                                        <i className="fa fa-cube mr-2"></i> Other
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {showPopup && <IMAP onClose={() => togglePopup(false)} />}
        </>
    );
}

export default WorkspaceSettings;