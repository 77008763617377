import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, Toaster } from "react-hot-toast";
import { saveIMAPConfig } from "../../features/emailConfig/emailConfigSlice";

const IMAP = ({ onClose }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      service: "imap",
      imapServer: "",
      imapPort: "",
      imapUsername: "",
      imapPassword: "",
      security: "",
    },
    validationSchema: Yup.object({
      service: Yup.string().required("IMAP Server is required"),
      imapServer: Yup.string().required("IMAP Server is required"),
      imapPort: Yup.number().required("IMAP Port is required"),
      imapUsername: Yup.string().required("Username is required"),
      imapPassword: Yup.string().required("Password is required"),
      security: Yup.string().required("Security is required"),
    }),

    onSubmit: (values) => {
      // Dispatch the saveIMAPConfig action with the workspaceId and form values
      dispatch(saveIMAPConfig({ workspaceId: id, emailConfig: values })).then(
        (action) => {
          if (saveIMAPConfig.fulfilled.match(action)) {
            toast.success("IMAP integrated successfully!", { duration: 2000 });
            onClose();
          } else {
            toast.error("Error integrating IMAP.");
          }
        }
      );
    },
  });

  return (
    <>
      <div>
        <Toaster />
      </div>
      <div className="popup-overlay">
        <div
          className="popup-content"
          style={{ height: "calc(90% - 40px)", overflowY: "auto" }}
        >
          <div className="container">
            <h3>IMAP Integration</h3>
            <form onSubmit={formik.handleSubmit}>
              <input
                type="hidden"
                name="service"
                value="imap"
                onChange={formik.handleChange}
              />
              <div className="form-group">
                <label className="form-label" htmlFor="imapServer">
                  IMAP Server
                </label>
                <input
                  type="text"
                  id="imapServer"
                  className="form-control"
                  placeholder="Enter IMAP Server"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.imapServer}
                  required
                />
                {formik.touched.imapServer && formik.errors.imapServer ? (
                  <div className="error">{formik.errors.imapServer}</div>
                ) : null}
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="imapPort">
                  IMAP Port
                </label>
                <input
                  type="number"
                  id="imapPort"
                  className="form-control"
                  placeholder="Enter IMAP Port"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.imapPort}
                  required
                />
                {formik.touched.imapPort && formik.errors.imapPort ? (
                  <div className="error">{formik.errors.imapPort}</div>
                ) : null}
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="imapUsername">
                  Username
                </label>
                <input
                  type="text"
                  id="imapUsername"
                  className="form-control"
                  placeholder="Enter Username"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.imapUsername}
                  required
                />
                {formik.touched.imapUsername && formik.errors.imapUsername ? (
                  <div className="error">{formik.errors.imapUsername}</div>
                ) : null}
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="imapPassword">
                  Password
                </label>
                <input
                  type="password"
                  id="imapPassword"
                  className="form-control"
                  placeholder="Enter Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.imapPassword}
                  required
                />
                {formik.touched.imapPassword && formik.errors.imapPassword ? (
                  <div className="error">{formik.errors.imapPassword}</div>
                ) : null}
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="security">
                  Security
                </label>
                <select
                  id="security"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.security}
                  required
                >
                  <option value="">Select Security</option>
                  <option value="SSL">SSL</option>
                  <option value="TLS">TLS</option>
                  <option value="STARTTLS">STARTTLS</option>
                  <option value="None">None</option>
                </select>
                {formik.touched.security && formik.errors.security ? (
                  <div className="error">{formik.errors.security}</div>
                ) : null}
              </div>
              <div className="popup-buttons">
                <button
                  className="btn btn-secondary mr-2 popup-button"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button className="btn btn-primary popup-button" type="submit">
                  Integrate
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default IMAP;
