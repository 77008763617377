import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  upgradePlanAPI,
  attachPaymentMethodAPI,
  fetchUserSavedPaymentMethodsAPI,
  removePaymentMethodAPI,
  fetchPaymentHistoryAPI,
} from "./subscriptionAPI";
// import { setUserPlanDetails } from '../userAccount/userAccountSlice';

export const upgradePlan = createAsyncThunk(
  "subscription/upgradePlan",
  async (upgradeData, { dispatch, rejectWithValue }) => {
    try {
      const response = await upgradePlanAPI(upgradeData);
      const newPlanDetails = response.data;

      // dispatch(setUserPlanDetails(newPlanDetails.subs));
      return newPlanDetails.message;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const attachPaymentMethod = createAsyncThunk(
  "subscription/attachPaymentMethod",
  async ({ paymentMethodId }, { rejectWithValue }) => {
    try {
      const response = await attachPaymentMethodAPI({ paymentMethodId });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSavedPaymentMethods = createAsyncThunk(
  "subscription/fetchSavedPaymentMethods",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await fetchUserSavedPaymentMethodsAPI(userId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removePaymentMethod = createAsyncThunk(
  "subscription/removePaymentMethod",
  async (paymentMethodId, { dispatch, rejectWithValue }) => {
    try {
      // API call to your backend to remove the payment method from Stripe
      await removePaymentMethodAPI(paymentMethodId);

      // Refetch the saved payment methods to update the state
      dispatch(fetchSavedPaymentMethods());
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPaymentHistory = createAsyncThunk(
  "subscription/fetchPaymentHistory",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchPaymentHistoryAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  upgrading: false,
  attachingPaymentMethod: false,
  fetchingPaymentMethods: false,
  savedPaymentMethods: [],
  upgradeError: null,
  attachPaymentMethodError: null,
  fetchPaymentMethodsError: null,
  paymentHistory: [],
  fetchingPaymentHistory: false,
  fetchPaymentHistoryError: null,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    resetUpgradeError: (state) => {
      state.upgradeError = null;
    },
    resetAttachPaymentMethodError: (state) => {
      state.attachPaymentMethodError = null;
    },
    resetFetchPaymentMethodsError: (state) => {
      state.fetchPaymentMethodsError = null;
    },
    resetFetchPaymentHistoryError: (state) => {
      state.fetchPaymentHistoryError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Upgrade plan
      .addCase(upgradePlan.pending, (state) => {
        state.upgrading = true;
        state.upgradeError = null;
      })
      .addCase(upgradePlan.fulfilled, (state) => {
        state.upgrading = false;
      })
      .addCase(upgradePlan.rejected, (state, action) => {
        state.upgrading = false;
        state.upgradeError = action.payload || "Failed to upgrade plan";
      })
      // Attach payment method
      .addCase(attachPaymentMethod.pending, (state) => {
        state.attachingPaymentMethod = true;
        state.attachPaymentMethodError = null;
      })
      .addCase(attachPaymentMethod.fulfilled, (state) => {
        state.attachingPaymentMethod = false;
      })
      .addCase(attachPaymentMethod.rejected, (state, action) => {
        state.attachingPaymentMethod = false;
        state.attachPaymentMethodError =
          action.payload || "Failed to attach payment method";
      })
      // Cases for fetchSavedPaymentMethods
      .addCase(fetchSavedPaymentMethods.pending, (state) => {
        state.fetchingPaymentMethods = true;
        state.fetchPaymentMethodsError = null;
      })
      .addCase(fetchSavedPaymentMethods.fulfilled, (state, action) => {
        state.fetchingPaymentMethods = false;
        state.savedPaymentMethods = action.payload;
      })
      .addCase(fetchSavedPaymentMethods.rejected, (state, action) => {
        state.fetchingPaymentMethods = false;
        state.fetchPaymentMethodsError =
          action.payload || "Failed to fetch saved payment methods";
      })
      // Cases for fetchPaymentHistory
      .addCase(fetchPaymentHistory.pending, (state) => {
        state.fetchingPaymentHistory = true;
        state.fetchPaymentHistoryError = null;
      })
      .addCase(fetchPaymentHistory.fulfilled, (state, action) => {
        state.fetchingPaymentHistory = false;
        state.paymentHistory = action.payload;
      })
      .addCase(fetchPaymentHistory.rejected, (state, action) => {
        state.fetchingPaymentHistory = false;
        state.fetchPaymentHistoryError =
          action.payload || "Failed to fetch payment history";
      });
  },
});

export const {
  resetUpgradeError,
  resetAttachPaymentMethodError,
  resetFetchPaymentMethodsError,
  resetFetchPaymentHistoryError,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
