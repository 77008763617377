import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { generateExampleEmailAPI } from "./aiAPI";

export const generateExampleEmail = createAsyncThunk(
  "ai/generateExampleEmail",
  async ({ workspaceId, emailData }, { rejectWithValue }) => {
    try {
      const response = await generateExampleEmailAPI(workspaceId, emailData);

      return response.data;
    } catch (error) {
      let errorMessage = "Failed to generate Example Email Conversation";

      // Check if the error status is 404
      if (error.response && error.response.status === 404) {
        errorMessage = "No valid API key found.";
      } else {
        errorMessage = error.response?.data?.message || errorMessage;
      }

      return rejectWithValue(errorMessage);
    }
  }
);

const initialState = {
  exampleEmail: "",
  generatingStatus: "idle",
  error: null,
};

const aiSlice = createSlice({
  name: "ai",
  initialState,
  reducers: {
    resetGeneratingStatusAndError: (state) => {
      state.generatingStatus = "idle";
      state.error = null;
    },
    resetAll1: (state) => {
      return initialState; // This will reset all the states to their initial values
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateExampleEmail.pending, (state) => {
        state.generatingStatus = "loading";
      })
      .addCase(generateExampleEmail.fulfilled, (state, action) => {
        state.generatingStatus = "succeeded";
        state.exampleEmail = action.payload.generatedEmail;
      })
      .addCase(generateExampleEmail.rejected, (state, action) => {
        state.generatingStatus = "failed";
        state.error =
          action.payload || "Failed to generate Example Email Conversation";
      });
  },
});

export const { resetGeneratingStatusAndError, resetAll1 } = aiSlice.actions;
export default aiSlice.reducer;
