import React, { useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  loginUser,
  fetchUser,
  resetAuthStatus,
} from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import "./form.css";
import { Link } from "react-router-dom";
// import gradientlogo from '../../assets/images/bellio-digital-gradient.gif';
import logo from "../../assets/images/Bellio-Digital-AI-Gradient.png";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authStatus = useSelector((state) => state.auth.status);
  const authError = useSelector((state) => state.auth.error);
  const signupMethod = useSelector((state) => state.auth.signupMethod);

  const handleGoogleSignup = () => {
    const googleLoginURL = `${process.env.REACT_APP_API_URL}/api/auth/google/login`;
    const newWindow = window.open(
      googleLoginURL,
      "_blank",
      "width=500,height=600"
    );

    const handleStorageChange = (event) => {
      if (event.key === "authSuccess" && event.newValue === "true") {
      }
      dispatch(fetchUser()); // Dispatch the fetchUser action
      localStorage.removeItem("authSuccess"); // Clean up
    };

    window.addEventListener("storage", handleStorageChange);

    // Optional: Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(12, "Password should be at least 12 characters")
        .required("Required"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values));
    },
  });

  React.useEffect(() => {
    if (authStatus === "succeeded") {
      navigate("/dashboard");
    }
    if (authStatus === "failed") {
      toast.error(authError);
    }
  }, [authStatus, navigate, dispatch]);

  // This useEffect will only run when the component is unmounted
  React.useEffect(() => {
    return () => {
      dispatch(resetAuthStatus());
    };
  }, [dispatch]);

  return (
    <div className="container px-4 py-4 mx-auto auth-form">
      <div>
        <Toaster toastOptions={{
    style: {
      maxWidth: '500px',
    },
  }}/>
      </div>
      <div className="card card0 card-shadow">
        <div className="d-flex flex-lg-row flex-column-reverse">
          <div className="card card1">
            <form onSubmit={formik.handleSubmit}>
              <div className="row justify-content-center my-auto">
                <div className="col-md-8 col-10 my-5">
                  <div className="row justify-content-center px-3 mb-5">
                    <img id="logo" src={logo} width="180" />
                  </div>

                  <div className="form-group">
                    <label className="form-control-label text-muted">
                      Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder="email"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="error">{formik.errors.email}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="form-control-label text-muted">
                      Password
                    </label>
                    <input
                      type="password"
                      id="psw"
                      name="password"
                      placeholder="Password"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <div className="error">{formik.errors.password}</div>
                    ) : null}
                  </div>
                  <div className="row justify-content-center my-2 px-3">
                    <button className="btn-block btn-color">Login</button>
                  </div>

                  <div className="row justify-content-center my-2">
                    <Link to="/forgot-password">
                      <small className="text-muted">
                        Forgot your password?
                      </small>
                    </Link>
                  </div>
                  <div className="row justify-content-center my-4">
                    <button
                      type="button"
                      class="login-with-google-btn"
                      onClick={handleGoogleSignup}
                    >
                      <span className="button-content">
                        <span className="google-logo"></span>
                        Sign in with Google
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="bottom text-center mb-5">
              <Link to="/register" className="sm-text mx-auto mb-3">
                Don't have an account?
                <button className="btn btn-white ml-2">Create new</button>
              </Link>
            </div>
          </div>
          <div className="card card2">
            <div className="my-auto mx-md-5 px-md-5 right">
              <h3 className="text-white">We are more than just a company</h3>
              <small className="text-white">
                Your experience is something that we value above all else. Your
                time is valuable. Your problems are our problems. Your success
                is our success. If there is ever anything you need help with,
                please don’t hesitate to reach out to us!
              </small>
              <div className="privacy-terms-links">
                <a
                  href="https://belliodigital.ai/privacy-policy"
                  className="text-light"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                <span className="text-light"> | </span>
                <a
                  href="https://belliodigital.ai/terms-of-service"
                  className="text-light"
                  target="_blank"
                >
                  Terms of Service
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
