import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import mastercard from '../../assets/images/default-images/MasterCard_Logo.svg_.webp';

const UpgradePlan = () => {

    return (
        <>
        <div className="container mt-4 mb-5">
            <div className="settings-header mb-4">
                <h2>Payment History</h2>
            </div>
            <div className='row'>
                <div className='col-md-4 mt-2'>
                    {/* Upgrade */}
                    <div class="section mb-4 campaign-card">
                        <div class="card-header" style={{ backgroundImage: "linear-gradient(130deg, #0700DD 0%, #00f2ff 100%)" }}>
                            <h5 class="card-title text-white text-uppercase text-center">Basic</h5>
                            <h6 class="h1 text-white text-center">$30<span class="h6 text-white-50"></span></h6>
                        </div>
                        <div class="card-body">
                            <ul class="list-unstyled mb-4" style={{ textAlign: 'left' }}>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>5 Workspaces</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>15 Forms Creation</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>500 Entries Saving in Sheet</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>100 Draft Email Creation</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>Example Email Generation</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{color:"#007bff"}}></i></span>Priority Support</li>
                            </ul>
                            <button className="btn btn-primary card-button mt-3">Upgrade</button>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 mt-2'>
                    {/* Upgrade */}
                    <div class="section mb-4 campaign-card">
                        <div class="card-header" style={{ backgroundImage: "linear-gradient(130deg, #0700DD 0%, #00f2ff 100%)" }}>
                            <h5 class="card-title text-white text-uppercase text-center">Standard</h5>
                            <h6 class="h1 text-white text-center">$75<span class="h6 text-white-50"></span></h6>
                        </div>
                        <div class="card-body">
                            <ul class="list-unstyled mb-4" style={{ textAlign: 'left' }}>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>25 Workspaces</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>100 Forms Creation</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>7000 Entries Saving in Sheet</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>100 Draft Email Creation</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>Example Email Generation</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{color:"#007bff"}}></i></span>Priority Support</li>
                            </ul>
                            <button className="btn btn-primary card-button mt-3">Upgrade</button>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 mt-2'>
                    {/* Upgrade */}
                    <div class="section mb-4 campaign-card">
                        <div class="card-header" style={{ backgroundImage: "linear-gradient(130deg, #0700DD 0%, #00f2ff 100%)" }}>
                            <h5 class="card-title text-white text-uppercase text-center">Business</h5>
                            <h6 class="h1 text-white text-center">$270<span class="h6 text-white-50"></span></h6>
                        </div>
                        <div class="card-body">
                            <ul class="list-unstyled mb-4" style={{ textAlign: 'left' }}>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>Unlimited Workspaces</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>Unlimited Forms Creation</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>50000 Entries Saving in Sheet</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>25000 Draft Email Creation</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>Example Email Generation</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>Priority Support</li>
                                <li class="mb-3"><span class="mr-3"><i class="fa fa-check" style={{ color: "#007bff" }}></i></span>Access to Beta Features</li>
                            </ul>
                            <button className="btn btn-primary card-button mt-3">Upgrade</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default UpgradePlan;