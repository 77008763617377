import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from '../auth/authSlice';
import { createProductServiceAPI, fetchProductsAPI, updateProductServiceAPI, deleteProductServiceAPI } from './productServiceAPI';

export const addProductService = createAsyncThunk('productService/addProductService', async (productServiceData) => {
    const response = await createProductServiceAPI(productServiceData);
    return response.data;
});

export const updateProductService = createAsyncThunk(
    'productService/updateProductService',
    async ({ productServiceId, updates }) => {
        const response = await updateProductServiceAPI({ productServiceId, updates });
        return response.data;
    }
);

export const deleteProductService = createAsyncThunk('productService/deleteProductService', async (productServiceId) => {
    const response = await deleteProductServiceAPI(productServiceId);
    return response.data;
});

export const fetchProducts = createAsyncThunk(
    'productService/fetchProducts',
    async (workspaceId) => {
        const response = await fetchProductsAPI(workspaceId);
        return response.data;
    }
);

const initialState = {
    products: [],
    productServiceStatus: 'idle',
    fetchProductsStatus: 'idle',
    error: null,
};

const productServiceSlice = createSlice({
    name: 'productService',
    initialState,
    reducers: {
        resetProductServiceStatus: (state) => {
            state.productServiceStatus = 'idle';
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addProductService.pending, (state) => {
                state.productServiceStatus = 'loading';
            })
            .addCase(addProductService.fulfilled, (state, action) => {
                state.productServiceStatus = 'succeeded';
                state.products.push(action.payload);
            })
            .addCase(addProductService.rejected, (state, action) => {
                state.productServiceStatus = 'failed';
                state.error = action.error.message || action.payload.message;
            })
            .addCase(fetchProducts.pending, (state) => {
                state.fetchProductsStatus = 'loading';
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.fetchProductsStatus = 'succeeded';
                state.products = action.payload;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.fetchProductsStatus = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateProductService.pending, (state) => {
                state.productServiceStatus = 'loading';
            })
            .addCase(updateProductService.fulfilled, (state, action) => {
                state.productServiceStatus = 'succeeded';
                // Find the product/service by ID and update it
                const index = state.products.findIndex(product => product._id === action.payload._id);
                if (index !== -1) {
                    state.products[index] = action.payload;
                }
            })
            .addCase(updateProductService.rejected, (state, action) => {
                state.productServiceStatus = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteProductService.fulfilled, (state, action) => {
                state.productServiceStatus = 'succeeded';
                // Filter out the deleted product/service
                state.products = state.products.filter(product => product._id !== action.payload);
            })
            .addCase(deleteProductService.rejected, (state, action) => {
                state.productServiceStatus = 'failed';
                state.error = action.error.message;
            })
            .addCase(logout.fulfilled, (state) => {
                // Reset the state to its initial value on successful logout
                return initialState;
            });
    },
});

export const { resetProductServiceStatus } = productServiceSlice.actions;
export default productServiceSlice.reducer;