import CryptoJS from 'crypto-js';
import api from '../../helpers/api';

function generateSignature(data) {
    const hash = CryptoJS.HmacSHA256(JSON.stringify(data), process.env.REACT_APP_SHARED_SECRET);
    return CryptoJS.enc.Hex.stringify(hash);
}

export const createForm = (formData) => {
    return api.post('/api/form', formData);
};

export const updateForm = ({ formId, updatedData }) => {
    return api.put(`/api/form/${formId}`, updatedData);
};

export const deleteForm = async (formId, webhookId) => {
    try {
        const response = await api.delete(`/api/form/${formId}`, {
            data: { webhookId }
        });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const fetchWebhookData = (uniqueId) => {
    return api.get(`/webhook/webhook-data/${uniqueId}`);
};

export const saveSelectedFields = (selectedFields) => {
    return api.post('/api/form/save-selected-fields', selectedFields);
};

export const fetchFormsForWorkspace = (workspaceId) => {
    return api.get(`/api/form/all?workspaceId=${workspaceId}`);
};

export const processWebhookData = (uniqueId, webhookData) => {
    const signature = generateSignature(webhookData);
    return api.post(`/webhook/${uniqueId}`, webhookData, {
        headers: {
            'X-Webhook-Signature': signature
        }
    });
};