import React from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { saveSelectedFieldsAsync } from '../../features/form/formSlice';

const Step4 = ({ nextClick, prevClick }) => {
    const dispatch = useDispatch();
    const webhookData = useSelector(state => state.form.webhookData);
    const formId = useSelector(state => state.form.form._id);
    const saveFieldsStatus = useSelector(state => state.form.saveFieldsStatus);
    const saveFieldsError = useSelector(state => state.form.saveFieldsError);
    const fieldsToSelect = Object.keys(webhookData);

    const selectedFieldsForDecision = useSelector(state => state.form.form.selectedFieldsForDecision);
    const selectedEmailField = useSelector(state => state.form.form.selectedEmailField);
    const selectedNameField = useSelector(state => state.form.form.selectedNameField);


    const formik = useFormik({
        initialValues: {
            selectedNameField: selectedNameField || '',
            selectedEmailField: selectedEmailField || '',
            selectedFieldsForDecision: selectedFieldsForDecision || []
        },
        validationSchema: Yup.object({
            selectedNameField: Yup.string()
                .notOneOf([''], "Please select a valid field"),
            selectedEmailField: Yup.string()
                .notOneOf([''], "Please select a valid field"),
            selectedFieldsForDecision: Yup.array()
                .min(1, 'Please select at least one field')
        }),
        onSubmit: async (values) => {
            const response = await dispatch(saveSelectedFieldsAsync({
                ...values,
                formId: formId
            }));
            if (saveSelectedFieldsAsync.fulfilled.match(response)) {
                nextClick();
            } else if (saveFieldsError) {
                toast.error(saveFieldsError);
            }
        }
    });

    return (
        <div className="multisteps-form__panel pl-2 pr-2 rounded bg-white js-active" data-animation="scaleIn">
            <div className="multisteps-form__content">
                <h3 className='mb-3 mt-3'>AI Customization</h3>
                <div className="form-row mt-2">
                    <div className="col-12 col-sm-12 mb-3">
                        <label>Select User Name Field:</label>
                        <select
                            className="multisteps-form__input form-control"
                            name="selectedNameField"
                            value={formik.values.selectedNameField}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        >
                            <option value="" disabled>Select...</option>
                            {fieldsToSelect.map(field => (
                                <option key={field} value={field}>{field}</option>
                            ))}
                        </select>
                        {formik.touched.selectedNameField && formik.errors.selectedNameField ? (
                            <div className="text-danger">{formik.errors.selectedNameField}</div>
                        ) : null}
                    </div>

                    <div className="col-12 col-sm-12 mb-3">
                        <label>Select Email Field:</label>
                        <select
                            className="multisteps-form__input form-control"
                            name="selectedEmailField"
                            value={formik.values.selectedEmailField}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        >
                            <option value="" disabled>Select...</option>
                            {fieldsToSelect.map(field => (
                                <option key={field} value={field}>{field}</option>
                            ))}
                        </select>
                        {formik.touched.selectedEmailField && formik.errors.selectedEmailField ? (
                            <div className="text-danger">{formik.errors.selectedEmailField}</div>
                        ) : null}
                    </div>

                    <div className="col-12 col-sm-12 mb-3">
                        <label>Choose fields for AI-driven lead detection and email responses:</label>
                        <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                        {fieldsToSelect.map(field => (
                            <div key={field} className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="selectedFieldsForDecision"
                                    value={field}
                                    checked={formik.values.selectedFieldsForDecision.includes(field)}
                                    onChange={formik.handleChange}
                                />
                                <label className="form-check-label">{field}</label>
                            </div>
                        ))}
                        </div>
                        {formik.touched.selectedFieldsForDecision && formik.errors.selectedFieldsForDecision ? (
                            <div className="text-danger">{formik.errors.selectedFieldsForDecision}</div>
                        ) : null}
                    </div>
                </div>
                <div className="button-row d-flex mt-3 pb-3">
                    <div className="ml-auto">
                        <button className="btn btn-secondary mr-2 popup-button" onClick={prevClick}>
                            Previous
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary popup-button"
                            onClick={formik.handleSubmit}
                            disabled={saveFieldsStatus === 'loading'}
                        >
                            {saveFieldsStatus === 'loading' ? 'Loading...' : 'Next'}
                        </button>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step4;