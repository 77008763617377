import React, { useState, useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { fetchWebhookDataAsync, resetWebhookStatus } from '../../features/form/formSlice';
import './steps.css';
import webhook from '../../assets/images/webhook.png';

const Step2 = ({ nextClick, prevClick }) => {
    const dispatch = useDispatch();
    const webhookUniqueId = useSelector(state => state.form.webhookUniqueId);
    const webhookData = useSelector(state => state.form.webhookData);
    const webhookStatus = useSelector(state => state.form.webhookStatus);
    const webhookError = useSelector(state => state.form.webhookError);
    const [webhookUrl] = useState(`${process.env.REACT_APP_FRONTEND_URL}/webhook/${webhookUniqueId}`);

    const [copySuccess, setCopySuccess] = useState(false);

    const copyWebhookUrl = (event) => {
        event.preventDefault();
        const textField = document.createElement('textarea');
        textField.innerText = webhookUrl;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        setCopySuccess(true);
        setTimeout(() => {
            setCopySuccess(false);
        }, 2000);
    };

    const handleNextClick = () => {
        dispatch(fetchWebhookDataAsync(webhookUniqueId));
    };

    useEffect(() => {
        if (webhookStatus === 'succeeded') {
            nextClick();
            dispatch(resetWebhookStatus());
        } else if (webhookStatus === 'failed') {
            toast.error("We didn't receive data from the webhook request. Please hit and try again.");
        }
    }, [webhookStatus, nextClick]);

    return (
        <>
            <div><Toaster /></div>
            <div className="multisteps-form__panel pl-2 pr-2 rounded bg-white js-active" data-animation="scaleIn">
                <div className="multisteps-form__content">
                    <h3 className='mb-3 mt-3'>Webhook URL</h3>
                    <div className="form-row mt-4">
                        <div className="col-12 col-sm-12">
                            <label className="form-label" htmlFor="webhookUrl">
                                Webhook
                                <span className="ml-1">
                                    <button className={`btn btn-link btn-sm ${copySuccess ? 'shade-pink' : 'shade-orange'}`} onClick={copyWebhookUrl}>
                                        {copySuccess ? 'Copied' : 'Copy'}
                                    </button>
                                </span>
                            </label>
                            <input
                                className="multisteps-form__input form-control"
                                type="text"
                                id="webhookUrl"
                                name="webhookUrl"
                                placeholder="Webhook"
                                value={webhookUrl}
                                readOnly
                            />
                            <p className="text-muted mt-2">
                                We've created a webhook URL for you to effortlessly send requests to.
                            </p>
                            <div className="text-center mt-3">
                                <img src={webhook} width="120" alt="Webhook" />
                            </div>
                            <p className="text-center mt-3">
                                To begin, just send a request to the webhook URL provided above.
                                Then, continue by selecting the 'Next' button.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="button-row d-flex mt-3 pb-3">
                    <div className="ml-auto">
                        <button className="btn btn-secondary mr-2 popup-button" onClick={prevClick}>
                            Previous
                        </button>
                        <button
                            className="btn btn-primary popup-button"
                            onClick={handleNextClick}
                            disabled={webhookStatus === 'loading'}
                        >
                            {webhookStatus === 'loading' ? 'Loading...' : 'Next'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step2;