import React from 'react';
import Header from '../../components/Header/Header';
import IntegratedForms from '../../components/IntegratedForms/IntegratedForms';

const Forms = () => {
  return (
    <>
    <IntegratedForms />
    </>
  )
}

export default Forms