import React, { useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { forgotPassword, resetForgotPasswordState } from '../../features/auth/authSlice';
import './form.css';
import logo from '../../assets/images/Bellio-Digital-AI-Gradient.png';

const ForgotForm = () => {
    const dispatch = useDispatch();
    const { forgotPasswordLoading, forgotPasswordSuccess, forgotPasswordError } = useSelector(state => state.auth);

    useEffect(() => {
        if (forgotPasswordSuccess) {
            toast.success('Password reset email sent');
        } else if (forgotPasswordError) {
            toast.error(forgotPasswordError);
        }

        // Return a cleanup function to reset the state when the component unmounts
        return () => {
            dispatch(resetForgotPasswordState());
        };
    }, [forgotPasswordSuccess, forgotPasswordError]);

    const validationSchema = yup.object({
        email: yup.string().email('Invalid email format').required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(forgotPassword(values.email));
        },
    });

    return (
        <>
            <div><Toaster /></div>
            <div className="container px-4 py-4 mx-auto auth-form">
                <div className="card card0 card-shadow">
                    <div className="d-flex flex-lg-row flex-column-reverse">
                        <div className="card card1">
                            <div className="row justify-content-center my-auto">
                                <div className="col-md-8 col-10 my-5">
                                    <div className="row justify-content-center px-3 mb-5">
                                        <img id="logo" src={logo} />
                                    </div>

                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="form-group">
                                            <label className="form-control-label text-muted">Email</label>
                                            <input
                                                type="text"
                                                id="email"
                                                name="email"
                                                placeholder="Email"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="text-danger">{formik.errors.email}</div>
                                            ) : null}
                                        </div>

                                        <div className="row justify-content-center my-2 px-3">
                                            <button type="submit" className="btn-block btn-color">
                                                Reset Password
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                        <div className="card card2">
                            <div className="my-auto mx-md-5 px-md-5 right">
                                <h3 className="text-white">We are more than just a company</h3>
                                <small className="text-white">Your experience is something that we value above all else.  Your time is valuable.  Your problems are our problems.  Your success is our success.  If there is ever anything you need help with, please don’t hesitate to reach out to us!</small>
                                <div className="privacy-terms-links">
                                    <a href="https://belliodigital.ai/privacy-policy" className="text-light" target="_blank">Privacy Policy</a>
                                    <span className="text-light"> | </span>
                                    <a href="https://belliodigital.ai/terms-of-service" className="text-light" target="_blank">Terms of Service</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotForm