import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEmailConfigsForWorkspace, createOrUpdateIMAPConfig } from './emailConfigAPI';

// Async thunk to fetch email configurations for a workspace
export const fetchEmailConfigs = createAsyncThunk('emailConfig/fetchEmailConfigs', async (workspaceId) => {
    const response = await getEmailConfigsForWorkspace(workspaceId);
    return response.data;
});

export const saveIMAPConfig = createAsyncThunk('emailConfig/saveIMAPConfig', async ({ workspaceId, emailConfig }) => {
    const response = await createOrUpdateIMAPConfig(workspaceId, emailConfig);
    return response.data;
});

const initialState = {
    emailConfigs: {},
    emailConfigStatus: 'idle',
    emailConfigError: null,
};

const emailConfigSlice = createSlice({
    name: 'emailConfig',
    initialState,
    reducers: {
        resetEmailConfigStatus: (state) => {
            state.emailConfigs = {};
            state.emailConfigStatus = 'idle';
            state.emailConfigError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEmailConfigs.pending, (state) => {
                state.emailConfigStatus = 'loading';
            })
            .addCase(fetchEmailConfigs.fulfilled, (state, action) => {
                state.emailConfigStatus = 'succeeded';
                state.emailConfigs = action.payload;
            })
            .addCase(fetchEmailConfigs.rejected, (state, action) => {
                state.emailConfigStatus = 'failed';
                state.emailConfigs = {};
                state.emailConfigError = action.error.message;
            })
            .addCase(saveIMAPConfig.pending, (state) => {
                state.emailConfigStatus = 'loading';
            })
            .addCase(saveIMAPConfig.fulfilled, (state, action) => {
                state.emailConfigStatus = 'succeeded';
                state.emailConfigs = action.payload;
            })
            .addCase(saveIMAPConfig.rejected, (state, action) => {
                state.emailConfigStatus = 'failed';
                state.emailConfigError = action.error.message;
            });
    },
});

export const { resetEmailConfigStatus } = emailConfigSlice.actions;
export default emailConfigSlice.reducer;