import React, { useState, useEffect, useRef } from "react";
import { resetForm } from "../../features/form/formSlice";
import { resetProductServiceStatus } from "../../features/productService/productServiceSlice";
import { resetPlanState } from "../../features/plan/planSlice";
import { resetUserAccountStatus } from "../../features/userAccount/userAccountSlice";
import { useSelector, useDispatch } from "react-redux";
import { persistor } from "../../app/store";
import { logout } from "../../features/auth/authSlice";
import "./header.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/Bellio-Digital-AI-Logo-White.png";
import giflogo from "../../assets/images/bellio-digital.gif";
import panda from "../../assets/images/default-images/panda.png";

const Header = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetForm());
    dispatch(resetProductServiceStatus());
    dispatch(resetUserAccountStatus());
    dispatch(resetPlanState());
    persistor.purge(); // clearing the persisted state
    navigate("/login");
  };
  const user = useSelector((state) =>
    state.auth.user ? state.auth.user.user : null
  );

  const [userDropdownVisible, setUserDropdownVisible] = useState(false);
  const [notificationDropdownVisible, setNotificationDropdownVisible] =
    useState(false);

  const headerRef = useRef(null);
  const notificationIconRef = useRef(null);
  const notificationDropdownRef = useRef(null);

  const toggleUserDropdown = () => {
    setUserDropdownVisible(!userDropdownVisible);
    setNotificationDropdownVisible(false); // Close notification dropdown
  };

  const toggleNotificationDropdown = () => {
    setNotificationDropdownVisible(!notificationDropdownVisible);
    setUserDropdownVisible(false); // Close user dropdown
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        headerRef.current &&
        !headerRef.current.contains(event.target) &&
        notificationIconRef.current &&
        !notificationIconRef.current.contains(event.target)
      ) {
        setNotificationDropdownVisible(false);
      }

      // Close the user dropdown when clicking anywhere on the screen
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        setUserDropdownVisible(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <div className="header-container py-3">
      <div className="logo-image">
        <Link to={isAuthenticated ? "/dashboard" : "/login"}>
          <img src={logo} alt="Logo" width="160" />
        </Link>
      </div>
      <div className="header-icons" ref={headerRef}>
        {/* <div
          className={`icon ${notificationDropdownVisible ? 'active-notification' : ''}`}
          id="notificationIcon"
          onClick={toggleNotificationDropdown}
          ref={notificationIconRef}
        >
          <i className="fa fa-bell"></i>
          <div className={`notification-badge ${notificationDropdownVisible ? 'active-notification' : ''}`}>1</div>
          <div
            className={`notification-dropdown ${notificationDropdownVisible ? 'active-notification' : ''}`}
            ref={notificationDropdownRef}
          >
            <div className="notification-item">
              <div className="notification-heading">Important Update</div>
              <div className="notification-description">A new feature has been added to the system. This is a longer description that demonstrates how the description takes up a maximum of two lines.</div>
              <div className="divider"></div>
            </div>
            <div className="notification-item">
              <div className="notification-heading">Maintenance Notice</div>
              <div className="notification-description">Scheduled maintenance on September 15, 2023.</div>
              <div className="divider"></div>
            </div>
          </div>
        </div> */}
        {/* <i className="fa fa-cog icon"></i> */}
        {isAuthenticated && user && (
          <div className="user-profile" onClick={toggleUserDropdown}>
            <img
              src={user.profileImage ? user.profileImage : panda}
              alt="User"
              className="user-image"
            />
            <span className="user-name" style={{ textTransform: "capitalize" }}>
              {user.firstName} {user.lastName}
            </span>
            <div className="user-dropdown-icon">
              <i className="fa fa-caret-down"></i>
            </div>
            {userDropdownVisible && (
              <div className="user-dropdown">
                <div className="user-dropdown">
                  <Link to={`/profile`} className="user-dropdown-item">
                    Profile Settings
                  </Link>
                  <Link to={`/api-management`} className="user-dropdown-item">
                    API Management
                  </Link>
                  <Link
                    to={`/billing-and-subscription`}
                    className="user-dropdown-item"
                  >
                    Billing & Subscription
                  </Link>
                  <Link to={`/help-and-support`} className="user-dropdown-item">
                    Help & Support
                  </Link>
                  <div className="dropdown-divider"></div>
                  <a
                    className="user-dropdown-item"
                    href="#"
                    onClick={() => handleLogout()}
                  >
                    Logout
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
